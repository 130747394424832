import axios from 'axios'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { refresh } from '../utils/web3-utils'
import { TaskExecutionsResponse } from '../types/API'

const URL = process.env.REACT_APP_SERVER_BASE_URL
type NAry<N> = N | N[]
interface Filter {
  chainId?: NAry<number>
  smartVault?: NAry<string>
  task?: NAry<string>
  executionType?: NAry<string>
  token?: NAry<string>
  status?: NAry<string>
  reason?: NAry<string>
  executionPlanId?: NAry<string>
}

const useLogs = (
  id: string,
  page: number = 1,
  limit: number = 50,
  filter: Filter,
  refetchInterval: number = 0,
  enabled: boolean = true
): UseQueryResult<TaskExecutionsResponse> => {
  return useQuery<TaskExecutionsResponse>({
    queryKey: ['logs', id, page, filter],
    queryFn: () => fetchLogs(id, limit, page, filter),
    refetchInterval: refetchInterval,
    enabled: enabled,
  })
}

const fetchLogs = async (
  id: string,
  limit: number,
  page: number,
  filter: Filter
): Promise<TaskExecutionsResponse> => {
  if (!id) throw new Error('No id provided')

  try {
    const token = localStorage.getItem('token')
    const { data } = await axios.get<TaskExecutionsResponse>(`${URL}/relayer-executor/environments/${id}/executions`, {
      params: {
        limit,
        page,
        ...filter,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'x-auth-token': `${token}`,
      },
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        console.error('❗️You do not have permissions to view this page')
      } else if (error.response?.status === 401) {
        try {
          await refresh()
          return await fetchLogs(id, limit, page, filter)
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      } else {
        console.error('❗️An error occurred with this view')
      }
    }
    throw error
  }
}

export default useLogs
