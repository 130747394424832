import { useQuery } from '@tanstack/react-query'
import { EnvironmentGasResponse } from 'API'
import axios from 'axios'
import { formatTokenAmount } from '../utils/math-utils'
import { refresh } from '../utils/web3-utils'

const URL = process.env.REACT_APP_SERVER_BASE_URL
const AUTH_TOKEN = localStorage.getItem('token')

async function fetchEnvironmentGas(chainId: number): Promise<EnvironmentGasResponse> {
  const date = new Date()
  date.setHours(date.getHours() - 3)
  const isoDate = date.toISOString()
  try {
    const gas = await axios.get(`${URL}/web3/gas/params`, {
      params: {
        chainId: chainId,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'x-auth-token': `${AUTH_TOKEN}`,
      },
    })

    const median = await axios.get(`${URL}/web3/gas/median`, {
      params: {
        chainId: chainId,
        startDate: isoDate,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'x-auth-token': `${AUTH_TOKEN}`,
      },
    })

    return {
      currentGas: formatTokenAmount(gas?.data?.estimatedGasPrice, 9, { digits: 2 }),
      medianGas: median?.data ? formatTokenAmount(median?.data, 9, { digits: 2 }) : 0,
    }
  } catch (error) {
    if ((error as any).response?.status === 401) {
      try {
        await refresh()
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    console.error('Error fetching gas info:', error)
    throw error
  }
}

function useEnvironmentGas(chainId: number) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['gas', chainId],
    queryFn: () => fetchEnvironmentGas(chainId),
  })

  return { data, isLoading, error, refetch }
}

export default useEnvironmentGas
