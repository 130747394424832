import moment from 'moment'
import LogsItem from '../logs/LogItem'
import Address from '../../general/Address'
import { getEtherscanLink } from '../../../utils/web3-utils'
import { ExpandableContent, Extra, TableCellM, TableRowM, Line, ExtraInfo, FlexOptions, Messages } from './styles'
import useLogs from '../../../hooks/useLogs'
import { AggregatedTokenInfo } from './Monitor'

interface ExpandableComponentProps {
  isOpen: boolean
  item: AggregatedTokenInfo
  width: number
  selectedOpenAll: boolean
  environment: string
}

function ExpandableComponent({ isOpen, item, width, selectedOpenAll, environment }: ExpandableComponentProps) {
  const defaultStatus = [
    'notSimulated',
    'simulationFailed',
    'simulationReverted',
    'simulationSucceeded',
    'executionDelayed',
    'executionSucceeded',
    'executionReverted',
    'executionNotReached',
    'transactionReverted',
  ]

  const { data, isLoading } = useLogs(
    environment,
    1,
    20,
    { chainId: item.chainId, token: item?.address, status: defaultStatus },
    0,
    isOpen
  )

  return (
    <>
      <TableRowM>
        <TableCellM colSpan={7}>
          <ExpandableContent expanded={isOpen || selectedOpenAll}>
            <TableRowM>
              <TableCellM colSpan={7}>
                <Extra>
                  <div>
                    {item?.source.map((s) => (
                      <Line key={s.address}>
                        <Address address={s.address} short chainId={item?.chainId} showIdentity={false} /> - {s.balance}{' '}
                        -{' '}
                        <a
                          href={getEtherscanLink(item.chainId || -1, `${item?.address}?a=${s.address}`, 'token')}
                          style={{ textDecoration: 'underline' }}
                        >
                          Check{' '}
                        </a>
                      </Line>
                    ))}
                  </div>
                  <ExtraInfo>Price last updated: {moment(item?.priceUpdated).fromNow()}</ExtraInfo>
                  <FlexOptions>
                    <a
                      href={`/dashboard/environments/${environment}/logs?chainId=${item?.chainId}&token=${item?.address}&colored=true`}
                    >
                      Open Logs
                    </a>
                  </FlexOptions>
                </Extra>
              </TableCellM>
            </TableRowM>

            {isLoading && <Messages>Loading executions...</Messages>}
            {!isLoading && data?.data.length === 0 && <Messages>No data on executions</Messages>}

            {data && data.data.length > 0 &&
              data?.data?.map((task, i) => (
                <LogsItem
                  key={i}
                  item={task}
                  width={width}
                  colored={true}
                  handleSelectPlanId={() => console.log('click')}
                  lite={true}
                />
              ))}
          </ExpandableContent>
        </TableCellM>
      </TableRowM>
    </>
  )
}

export default ExpandableComponent
