import React from 'react'
import { Td, Content } from './styles'

interface TableCellProps {
  children: React.ReactNode;
  align?: 'left' | 'center' | 'right';
  lite?: boolean;
  colSpan?: number;
}

const TableCell: React.FC<TableCellProps> = ({ children, align = 'left', lite = false, ...props }) => {
  return (
    <Td align={align} lite={lite} {...props}>
      <Content align={align}>{children}</Content>
    </Td>
  )
}

export default TableCell
