import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Select from 'react-select'
import { Tab, Flexbox, Right, Loader, Section, H1 } from '../../utils/styles'
import Stats from './stats/Stats'
import Reports from './Reports'
import Monitors from './monitors/Monitor'
import Logs from './logs/Logs'
import Settings from './settings/Settings'
import Overview from './overview/Overview'
import Tasks from './tasks/Tasks'
import Address from '../general/Address'
import useCrons from '../../hooks/useCrons'
import useEnvironment from '../../hooks/useEnvironment'
import { CHAIN_INFO } from '../../constants/chainInfo'
import { parseName } from '../../utils/math-utils'
import useChainStats from '../../hooks/useChainStats'

interface DropdownOption {
  value: number;
  label: string;
}

type Params = {
  tab: string;
  id: string;
}

const Tabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('overview')
  const [dropdown, setDropDown] = useState<DropdownOption[]>([])
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null)
  const params = useParams<Params>()
  const tab = params.tab
  
  const { data: crons } = useCrons()
  const { data: environmentData, isLoading: isEnvLoading } = useEnvironment(params.id)
  const { data: chainData, isLoading: isChainLoading } = useChainStats(selectedOption?.value)

  const isLoading = isEnvLoading || isChainLoading

  useEffect(() => {
    if (environmentData) {
      const smartVaults = environmentData?.smartVaults
      const uniqueChainIDs = new Set<number>()
      smartVaults.forEach((item) => {
        uniqueChainIDs.add(item.chainId)
      })

      const sortedUniqueChainIDs = Array.from(uniqueChainIDs).sort((a, b) => a - b)
      const chainObjects: DropdownOption[] = sortedUniqueChainIDs.map((chainId) => ({
        value: chainId,
        label: CHAIN_INFO[chainId] ? CHAIN_INFO[chainId].name : `Chain ${chainId}`,
      }))

      setSelectedOption(chainObjects[0])
      setDropDown(chainObjects)
    }
  }, [environmentData])

  useEffect(() => {
    if (tab) {
      setActiveTab(tab)
    }
  }, [tab])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  const keyToIndexMap = dropdown.reduce<Record<string, number>>((map, obj, index) => {
    map[(index + 1).toString()] = index
    return map
  }, {})

  const handleKeyDown = (event: KeyboardEvent) => {
    const index = keyToIndexMap[event.key]
    if (index !== undefined) {
      handleMultiNetwork(dropdown[index])
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [dropdown, keyToIndexMap])

  const handleMultiNetwork = (selectedOption?: DropdownOption) => {
    setSelectedOption(selectedOption || null)
  }

  return (
    <Section>
      <Right>
        <Flexbox>
          {dropdown.length > 0 && (
            <StyledSelect
              classNamePrefix="Select"
              onChange={(selectedOption) => handleMultiNetwork(selectedOption as DropdownOption)}
              options={dropdown}
              value={selectedOption}
              defaultValue={dropdown?.length > 0 ? dropdown[0] : null}
            />
          )}
        </Flexbox>
      </Right>
      <H1>{environmentData && parseName(environmentData?.namespace)}</H1>
      {environmentData && (
        <Subtitle>
          {environmentData.namespace} | <Address address={environmentData.mimicId} short={true} showIdentity={false} chainId={selectedOption?.value} />
        </Subtitle>
      )}

      <Tab>
        <button onClick={() => handleTabClick('overview')} className={activeTab === 'overview' ? 'active' : ''}>
          Overview
        </button>
        <button onClick={() => handleTabClick('logs')} className={activeTab === 'logs' ? 'active' : ''}>
          Logs
        </button>
        <button onClick={() => handleTabClick('monitors')} className={activeTab === 'monitors' ? 'active' : ''}>
          Monitors
        </button>
        <button onClick={() => handleTabClick('tasks')} className={activeTab === 'tasks' ? 'active' : ''}>
          Tasks
        </button>
        <button onClick={() => handleTabClick('stats')} className={activeTab === 'stats' ? 'active' : ''}>
          Stats
        </button>
        <button onClick={() => handleTabClick('reports')} className={activeTab === 'reports' ? 'active' : ''}>
          Reports
        </button>
        <button onClick={() => handleTabClick('settings')} className={activeTab === 'settings' ? 'active' : ''}>
          Settings
        </button>
      </Tab>
      {isLoading && <Loader />}
      {selectedOption && chainData && environmentData && (
        <div>
          {activeTab === 'overview' && <Overview chainId={selectedOption?.value} chainInfo={chainData} crons={crons} />}
          {activeTab === 'logs' && <Logs chainId={selectedOption?.value} />}
          {activeTab === 'monitors' && <Monitors chainId={selectedOption?.value} />}
          {activeTab === 'stats' && <Stats chainId={selectedOption?.value} />}
          {activeTab === 'reports' && <Reports />}
          {activeTab === 'tasks' && <Tasks chainId={selectedOption?.value} smartVaults={environmentData.smartVaults} />}
          {activeTab === 'settings' &&
            (selectedOption ? (
              <Settings chainId={selectedOption?.value} environment={environmentData} chainInfo={chainData} />
            ) : (
              <Loader />
            ))}
        </div>
      )}
    </Section>
  )
}

const Subtitle = styled.p`
  margin-top: -30px;
  margin-bottom: 80px;
  font-family: 'DMSans';
  font-size: 16px;
  line-height: 26px;
  color: #dedee4;
  opacity: 0.5;
`

const StyledSelect = styled(Select)`
  .Select__control {
    min-width: 300px;
    border-radius: 10px;
    color: white;
    background: #2a2b3d;
    cursor: pointer;
    border-color: hsl(236.84deg 18.45% 20.2%);
  }

  .Select__single-value {
    color: white;
  }
  .Select__menu {
    color: white;
    background: #2a2b3d;
  }
  .Select__option {
    &:hover {
      background-color: #6f5ce6;

      &.Select__option--is-focused {
        background-color: #9487e5;
      }
    }
  }
`

export default Tabs
