import { useQuery } from '@tanstack/react-query'
import { BotBalanceResponse } from 'API'
import axios from 'axios'
import { formatTokenAmount } from '../utils/math-utils'
import { refresh } from '../utils/web3-utils'

const URL = process.env.REACT_APP_SERVER_BASE_URL
const AUTH_TOKEN = localStorage.getItem('token')

async function fetchBotBalance(chainId: number): Promise<BotBalanceResponse> {
  try {
    const balances = await axios.get(`${URL}/web3/wallets/balances`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'x-auth-token': `${AUTH_TOKEN}`,
      },
    })

    const price = await axios.get(`${URL}/price-oracle/prices/${chainId}/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'x-auth-token': `${AUTH_TOKEN}`,
      },
    })

    const balanceInfo = Object.values(balances.data).reduce((acc: number, obj: any) => {
      const value = obj[chainId]
      return acc + (parseInt(value) || 0)
    }, 0)

    return {
      balance: formatTokenAmount(balanceInfo, 18, { digits: 2 }),
      price: price?.data?.price || 0,
      balanceUsd:
        price?.data?.price !== 0
          ? (Number(formatTokenAmount(balanceInfo, 18, { digits: 10 })) * price.data.price).toFixed(2)
          : null,
    }
  } catch (error) {
    if ((error as any).response?.status === 401) {
      try {
        await refresh()
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    console.error('Error fetching bot balance:', error)
    throw error
  }
}

function useBotBalance(chainId: number) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['botBalance', chainId],
    queryFn: () => fetchBotBalance(chainId),
  })

  return { data, isLoading, error, refetch }
}

export default useBotBalance
