import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { ScrollTable, ContainerTable, Flexbox, Loader, Right, ButtonViolet } from '../../../utils/styles'
import CustomConfirmationModal from '../../../components/CustomConfirmationModal'
import NetworksForm from './NetworksForm'
import FormModal from '../../FormModal'
import MiniSwitch from '../../formUtils/MiniSwitch'
import deleteIcon from '../../../assets/delete.png'
import { refresh } from '../../../utils/web3-utils'
import { convertWeiToGwei, convertGweiToWei } from '../../../utils/web3-utils'
import edit from '../../../assets/edit.png'
import save from '../../../assets/save.png'
import cancel from '../../../assets/cancel.png'
import toast, { Toaster } from 'react-hot-toast'

interface RelayerData {
  _id: string
  chainId: number
  denied: boolean
  name: string
  symbol: string
  subgraphUrl: string
  thresholdAmount: number
  gasThresholdAmount: number
  executorGasLimitBufferPct: number
  transactionDelaySeconds: number
  transactionsExpirationSeconds: number
  missingTxMinutesTimeout: number
  minimumMinutesBetweenExecutions: number
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const Web3Rpcs: React.FC = () => {
  const [relayerData, setRelayerData] = useState<RelayerData[] | null>(null)
  const [deleteParams, setDeleteParams] = useState<any | null>(null)
  const [editItem, setEditItem] = useState<RelayerData | any>(null)
  const [editIndex, setEditIndex] = useState<number | any>(null)
  const [editedProps, setEditedProps] = useState<RelayerData | any>(null)
  const [addNew, setAddNew] = useState(false)

  const fetchRelayerData = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get<RelayerData[]>(`${URL}/relayer-executor/chains`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      setRelayerData(response.data)
    } catch (error: any) {
      console.error('Relayer data error:', error)
      if (error.response?.status === 401) {
        try {
          await refresh()
          await fetchRelayerData()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
    }
  }

  useEffect(() => {
    fetchRelayerData()
  }, [])

  const handleDeleteClick = (item: any) => {
    setDeleteParams(item)
  }

  const handleConfirmDelete = async () => {
    if (deleteParams !== null) {
      try {
        const token = localStorage.getItem('token')
        await axios.delete(`${URL}/relayer-executor/chains/${deleteParams.chainId}`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-auth-token': `${token}`,
          },
        })

        fetchRelayerData()
      } catch (error: any) {
        console.error('There was an error deleting the network:', error)
      }

      setDeleteParams(null)
    }
  }

  const handleCancelDelete = () => {
    setDeleteParams(null)
  }

  const handleSuccess = () => {
    fetchRelayerData()
    setAddNew(false)
  }

  const handleEdit = (item: RelayerData, index: number) => {
    setEditIndex(index)
    setEditItem(item)
    setEditedProps({
      ...item,
      gasThresholdAmount: convertWeiToGwei(item.gasThresholdAmount),
    })
  }

  const handleCancelEdit = () => {
    setEditIndex(null)
  }

  const handleSaveEdit = async () => {
    const token = localStorage.getItem('token')
    const url = `${URL}/relayer-executor/chains/${editItem.chainId}`

    try {
      await axios.put(
        url,
        {
          chainId: editedProps?.chainId,
          name: editedProps?.name,
          symbol: editedProps?.symbol,
          denied: editedProps?.denied,
          subgraphUrl: editedProps?.subgraphUrl,
          thresholdAmount: parseInt(editedProps?.thresholdAmount),
          gasThresholdAmount: convertGweiToWei(parseInt(editedProps?.gasThresholdAmount)),
          executorGasLimitBufferPct: parseInt(editedProps?.executorGasLimitBufferPct),
          transactionDelaySeconds: parseInt(editedProps?.transactionDelaySeconds),
          transactionsExpirationSeconds: parseInt(editedProps?.transactionsExpirationSeconds),
          missingTxMinutesTimeout: parseInt(editedProps?.missingTxMinutesTimeout),
          minimumMinutesBetweenExecutions: parseInt(editedProps?.minimumMinutesBetweenExecution),
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-auth-token': `${token}`,
          },
        }
      )
      fetchRelayerData()
      toast('✅ Your changes were successfully saved')
    } catch (error: any) {
      if (error.response?.status === 403) {
        toast('❗️You dont have permissions to edit')
      } else if (error.response?.status === 401) {
        try {
          await refresh()
          await handleSaveEdit()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      } else {
        toast('❗️We couldnt save the edited changes')
      }
      console.error('There was an error deleting the token list item:', error)
    }
    setEditIndex(null)
  }

  const handleAddNewCancel = () => {
    setAddNew(false)
  }

  return (
    <div>
      <Right>
        <ButtonViolet onClick={() => setAddNew(!addNew)}>+ Add new</ButtonViolet>
      </Right>
      {addNew && (
        <FormModal onConfirm={handleSuccess} onCancel={handleAddNewCancel}>
          <NetworksForm onSuccess={handleSuccess} />
        </FormModal>
      )}

      {relayerData ? (
        <>
          <ScrollTable>
            <ContainerTable className="editingTable">
            <Toaster position="top-right" />
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Symbol</th>
                <th>Denied</th>
                <th>Subgraph</th>
                <th>Threshold Amount</th>
                <th>Gas Threshold</th>
                <th>Gas limit buffer</th>
                <th>Tx Delay</th>
                <th>Tx Expiration</th>
                <th>Tx Missing Timeout</th>
                <th>Min Between Exec.</th>
                <th>Actions</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {relayerData?.map((item, index) => {
                return (
                  <tr key={item._id}>
                    <td>{item?.chainId}</td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="large"
                          value={editedProps?.name}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              name: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.name
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="short"
                          value={editedProps?.symbol}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              symbol: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.symbol
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <MiniSwitch
                          ison={editedProps.denied}
                          onToggle={() =>
                            setEditedProps({
                              ...editedProps,
                              denied: !editedProps.denied,
                            })
                          }
                        />
                      ) : item.denied ? (
                        'Denied'
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="xx-large"
                          value={editedProps?.subgraphUrl}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              subgraphUrl: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.subgraphUrl
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="short"
                          value={editedProps?.thresholdAmount}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              thresholdAmount: e.target.value,
                            })
                          }
                        />
                      ) : (
                        '$ ' + item.thresholdAmount
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="short"
                          value={editedProps?.gasThresholdAmount}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              gasThresholdAmount: e.target.value,
                            })
                          }
                        />
                      ) : (
                        convertWeiToGwei(item.gasThresholdAmount) + ' gwei'
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="short"
                          value={editedProps?.executorGasLimitBufferPct}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              executorGasLimitBufferPct: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.executorGasLimitBufferPct + ' %'
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="short"
                          value={editedProps?.transactionDelaySeconds}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              transactionDelaySeconds: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.transactionDelaySeconds + ' sec'
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="short"
                          value={editedProps?.transactionsExpirationSeconds}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              transactionsExpirationSeconds: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.transactionsExpirationSeconds + ' sec'
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="short"
                          value={editedProps?.missingTxMinutesTimeout}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              missingTxMinutesTimeout: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.missingTxMinutesTimeout + ' min'
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="short"
                          value={editedProps?.minimumMinutesBetweenExecutions}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              minimumMinutesBetweenExecutions: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.minimumMinutesBetweenExecutions + ' min'
                      )}
                    </td>

                    <td>
                      {editIndex === index ? (
                        <Flexbox>
                          <img onClick={handleSaveEdit} src={save} alt="Save" />
                          <img onClick={handleCancelEdit} src={cancel} alt="Cancel" />
                        </Flexbox>
                      ) : (
                        <img onClick={() => handleEdit(item, index)} src={edit} alt="Edit" />
                      )}
                    </td>
                    <td>
                      <img onClick={() => handleDeleteClick(item)} src={deleteIcon} alt="Delete" />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </ContainerTable>
          </ScrollTable>
          {deleteParams !== null && (
            <CustomConfirmationModal
              message="Are you sure you want to delete this network?"
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default Web3Rpcs
