import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import { ContainerTable } from '../utils/styles'
import { refresh } from '../utils/web3-utils'
import { ButtonViolet, ShowMore, Loader } from '../utils/styles'

interface Logs {
  ingestionTime: number
  timestamp: number
  message: string
}
interface Data {
  events: Logs[]
  nextForwardToken: string
  nextBackwardToken: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const LogsStreams: React.FC = () => {
  const params = useParams()
  const id = params.id
  const name = window.location.href.split('/groups?name=')[1]
  const [events, setLogsEvents] = useState<Logs[] | null>(null)
  const [next, setNext] = useState<string | null>(null)
  const [prev, setPrev] = useState<string | null>(null)
  const [nextButton, setNextButton] = useState({
    disabled: false,
    message: 'Next Events',
  })
  const [prevButton, setPrevButton] = useState({
    disabled: false,
    message: 'Previous events',
  })

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token')

      const response = await axios.get<Data>(`${URL}/logs/${name}/${id}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      setLogsEvents(response.data?.events)
      setNext(response.data?.nextForwardToken || null)
      setPrev(response.data?.nextBackwardToken || null)
    } catch (error: any) {
      if (error.response?.status === 401) {
        try {
          await refresh()
          await fetchData()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
      console.error('Token list error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSeeMore = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get<Data>(`${URL}/logs/${name}/${id}`, {
        params: {
          nextToken: next,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      if (response.data?.events && response.data?.events.length > 0) {
        setLogsEvents(events ? events.concat(response.data?.events) : null)
      } else if (response?.data?.events?.length === 0) {
        setNextButton({ disabled: true, message: 'No more next events' })
      }
    } catch (error) {
      console.error('There was an error with the web3 form:', error)
    }
  }

  const handleSeePrev = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get<Data>(`${URL}/logs/${name}/${id}`, {
        params: {
          nextToken: prev,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      if (response.data?.events && response.data?.events.length > 0) {
        setLogsEvents(events ? response.data?.events.concat(events) : null)
      } else if (response?.data?.events?.length === 0) {
        setPrevButton({ disabled: true, message: 'No more previous events' })
      }
    } catch (error) {
      console.error('There was an error with the web3 form:', error)
    }
  }

  return (
    <Section>
      {events ? (
        <>
          <ContainerTable>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {events
                .sort((a, b) => b.timestamp - a.timestamp)
                .map((item, index) => (
                  <tr key={index}>
                    <td>{new Date(item.timestamp).toLocaleString()}</td>
                    <td>
                      <Message message={item.message} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </ContainerTable>
          <FlexBox>
            {prev && (
              <ButtonViolet onClick={handleSeePrev} disabled={prevButton.disabled}>
                {prevButton.message}
              </ButtonViolet>
            )}
            {next && (
              <ButtonViolet onClick={handleSeeMore} disabled={nextButton.disabled}>
                {nextButton.message}
              </ButtonViolet>
            )}
          </FlexBox>
        </>
      ) : (
        <Loader />
      )}
    </Section>
  )
}

const Message: React.FC<{ message: string }> = ({ message }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const messageLength = 120

  return (
    <>
      {message.length > messageLength ? (
        <>
          <span>{expanded ? message : message.substring(0, messageLength)}</span>
          <ShowMore
            onClick={() => {
              setExpanded(!expanded)
            }}
          >
            {expanded ? 'Hide' : 'Show more'}
          </ShowMore>
        </>
      ) : (
        <span>{message}</span>
      )}
    </>
  )
}

const Section = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 874px;
  max-width: 90%;
  td {
    cursor: pointer;
    max-width: 600px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  button:disabled,
  button[disabled] {
    background-color: #cccccc;
    color: #666666;
  }
`

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 60px;
`

export default LogsStreams
