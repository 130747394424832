import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { ScrollTable, ContainerTable, Flexbox, Loader, Right, ButtonViolet } from '../../../utils/styles'
import CustomConfirmationModal from '../../../components/CustomConfirmationModal'
import RpcsForm from './RpcsForm'
import FormModal from '../../FormModal'
import MiniSwitch from '../../formUtils/MiniSwitch'
import deleteIcon from '../../../assets/delete.png'
import { refresh } from '../../../utils/web3-utils'
import Network from '../../general/Network'
import edit from '../../../assets/edit.png'
import save from '../../../assets/save.png'
import cancel from '../../../assets/cancel.png'
import toast from 'react-hot-toast'

interface Data {
  _id: string
  active: boolean
  chainId: number
  score: number
  createdAt: string
  updatedAt: string
  type: string
  url: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const Rpcs: React.FC = () => {
  const [data, setData] = useState<Data[] | null>(null)
  const [deleteParams, setDeleteParams] = useState<any | null>(null)
  const [editItem, setEditItem] = useState<Data | any>(null)
  const [editIndex, setEditIndex] = useState<number | any>(null)
  const [editedProps, setEditedProps] = useState<Data | any>(null)
  const [addNew, setAddNew] = useState(false)

  const compareObjects = (a: Data, b: Data): number => {
    if (a.chainId !== b.chainId) {
      return a.chainId - b.chainId
    }

    if (a.type !== b.type) {
      return a.type.localeCompare(b.type)
    }

    return a.active === b.active ? 0 : a.active ? 1 : -1
  }

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get<Data[]>(`${URL}/web3/rpc-endpoints`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      response.data.sort(compareObjects)
      setData(response.data)
    } catch (error: any) {
      console.error('Rpc data error:', error)
      if (error.response?.status === 401) {
        try {
          await refresh()
          await fetchData()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleDeleteClick = (item: any) => {
    setDeleteParams(item)
  }

  const handleConfirmDelete = async () => {
    if (deleteParams !== null) {
      const token = localStorage.getItem('token')
      const url = `${URL}/web3/rpc-endpoints/${deleteParams._id}`

      try {
        await axios.delete(url, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-auth-token': `${token}`,
          },
        })
        fetchData()
      } catch (error) {
        console.error('There was an error deleting the network:', error)
      }

      setDeleteParams(null)
    }
  }

  const handleCancelDelete = () => {
    setDeleteParams(null)
  }

  const handleSuccess = () => {
    fetchData()
    setAddNew(false)
  }

  const handleEdit = (item: Data, index: number) => {
    setEditIndex(index)
    setEditItem(item)
    setEditedProps(item)
  }

  const handleCancelEdit = () => {
    setEditIndex(null)
  }

  const handleSaveEdit = async () => {
    const token = localStorage.getItem('token')
    const url = `${URL}/web3/rpc-endpoints/${editItem._id}`

    try {
      await axios.put(
        url,
        {
          ...editItem,
          type: editedProps?.type,
          active: editedProps?.active,
          url: editedProps?.url,
          score: parseFloat(editedProps?.score),
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-auth-token': `${token}`,
          },
        }
      )
      fetchData()
      toast('✅ Your changes were successfully saved')
    } catch (error: any) {
      if (error.response?.status === 403) {
        toast('❗️You dont have permissions to edit')
      } else if (error.response?.status === 401) {
        try {
          await refresh()
          await handleSaveEdit()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      } else {
        toast('❗️We couldnt save the edited changes')
      }
      console.error('There was an error deleting the token list item:', error)
    }
    setEditIndex(null)
  }

  const handleAddNewCancel = () => {
    setAddNew(false)
  }

  return (
    <div>
      <Right>
        <ButtonViolet onClick={() => setAddNew(!addNew)}>+ Add new</ButtonViolet>
      </Right>
      {addNew && (
        <FormModal onConfirm={handleSuccess} onCancel={handleAddNewCancel}>
          <RpcsForm onSuccess={handleSuccess} />
        </FormModal>
      )}
      {data ? (
        <>
          <ScrollTable>
            <ContainerTable className="editingTable">
            <thead>
              <tr>
                <th>Network</th>
                <th>Type</th>
                <th>Score</th>
                <th>URL</th>
                <th>Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Network network={item?.chainId} />
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          value={editedProps?.type}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              type: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.type
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="number"
                          value={editedProps?.score}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              score: e.target.value,
                            })
                          }
                        />
                      ) : item.score !== 0 ? (
                        item.score.toFixed(3)
                      ) : (
                        0
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="xx-large"
                          value={editedProps?.url}
                          onChange={(e) =>
                            setEditedProps({
                              ...editedProps,
                              url: e.target.value,
                            })
                          }
                        />
                      ) : (
                        item.url
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <MiniSwitch
                          ison={editedProps.active}
                          onToggle={() =>
                            setEditedProps({
                              ...editedProps,
                              active: !editedProps.active,
                            })
                          }
                        />
                      ) : item.active ? (
                        '🟢'
                      ) : (
                        '🔴'
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <Flexbox>
                          <img onClick={handleSaveEdit} src={save} alt="Save" />
                          <img onClick={handleCancelEdit} src={cancel} alt="Cancel" />
                        </Flexbox>
                      ) : (
                        <img onClick={() => handleEdit(item, index)} src={edit} alt="Edit" />
                      )}
                    </td>
                    <td>
                      <img onClick={() => handleDeleteClick(item)} src={deleteIcon} alt="Delete" />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </ContainerTable>
          </ScrollTable>
          {deleteParams !== null && (
            <CustomConfirmationModal
              message="Are you sure you want to delete this network?"
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default Rpcs
