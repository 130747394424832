import axios from 'axios'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { refresh } from '../utils/web3-utils'
import { TokenResponse } from '../types/API'

export type ManyTokenFilters = {
  chainId?: number
  addresses?: string[]
  symbol?: string
  name?: string
  spamCounterLimit?: number
  enabled?: boolean
  isNativeToken?: boolean
  isWrappedNativeToken?: boolean
}

const useManyTokenInfo = (
  tokenList: string[],
  filter: ManyTokenFilters,
  limit: number = 50,
  setLoadPage: (page: number) => void,
  setTotalPages: (pages: number) => void
): UseQueryResult<TokenResponse['data']> => {
  return useQuery<TokenResponse['data']>({
    queryKey: ['manyTokens', tokenList?.toString(), filter.chainId],
    queryFn: () => fetchAllTokens(tokenList, filter, limit, setLoadPage, setTotalPages),
    enabled: tokenList?.length > 0,
  })
}

const fetchAllTokens = async (
  tokenList: string[],
  filter: ManyTokenFilters,
  limit: number,
  setLoadPage: (page: number) => void,
  setTotalPages: (pages: number) => void
): Promise<TokenResponse['data']> => {
  let allData: TokenResponse['data'] = {}

  // Function to split the tokenList into chunks of a given size
  const chunkArray = (array: string[], size: number): string[][] => {
    return array.reduce((acc, _, i) => (i % size ? acc : [...acc, array.slice(i, i + size)]), [] as string[][])
  }

  // Split the tokenList into chunks
  const tokenChunks = chunkArray(tokenList, limit)

  setTotalPages(tokenChunks.length)
  for (let i = 0; i < tokenChunks.length; i++) {
    const chunk = tokenChunks[i]

    try {
      const data = await fetchTokenInfo(chunk, filter, limit)
      setLoadPage(i + 1)
      if (data) {
        allData = { ...allData, ...data }
      }
    } catch (error) {
      console.error('Error fetching data for chunk', i, error)
    }
  }

  return allData
}

const fetchTokenInfo = async (
  tokenList: string[],
  filter: ManyTokenFilters,
  limit: number
): Promise<TokenResponse['data']> => {
  if (!tokenList) return {}

  const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL
  const url = `${BASE_URL}/token-registry/tokens`

  try {
    const token = localStorage.getItem('token')

    const header = {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
      'x-auth-token': `${token}`,
    }

    const params = {
      addresses: tokenList,
      limit,
      ...filter,
    }

    const { data: { data } } = await axios.get<TokenResponse>(url, {
      headers: header,
      params: { ...params },
    })
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      try {
        await refresh()
        return await fetchTokenInfo(tokenList, filter, limit)
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    throw error
  }
}

export default useManyTokenInfo
