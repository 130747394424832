import styled from 'styled-components'
import { SeverityLevel } from '../../../types/API'

export const Card = styled.div`
  border-radius: 12px;
  background: ${({ theme }) => theme.panel};
  padding: 15px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const Title = styled.div`
  color: ${({ theme }) => theme.textWhite};
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 1px;
`

export const CloseButton = styled.button`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.textGrey};
  font-size: 17px;
  cursor: pointer;
  padding: 0 5px;
  margin: 0;
`

export const Description = styled.p`
  color: ${({ theme }) => theme.textGrey};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1px;
`

export const Footer = styled(Header)`
  align-items: center;
  margin-top: 5px;
`

export const Timestamp = styled.span`
  color: ${({ theme }) => theme.textGrey};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1px;
`

export const SeverityPill = styled.span<{ severity: SeverityLevel }>`
  background-color: ${({ theme, severity }) => {
    switch (severity) {
      case SeverityLevel.CRITICAL:
        return theme.critical
      case SeverityLevel.HIGH:
        return theme.high
      case SeverityLevel.MEDIUM:
        return theme.medium
      case SeverityLevel.LOW:
        return theme.low
      case SeverityLevel.INFORMATIONAL:
      default:
        return theme.informational
    }
  }};
  color: ${({ theme }) => theme.textWhite};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  padding: 4px 8px;
  border-radius: 12px;
  text-transform: capitalize;
`
