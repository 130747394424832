import axios from 'axios'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { refresh } from '../utils/web3-utils'

type TaskMetadata = Record<string, unknown>

const useTaskMetadata = (id: string, isOpen: boolean): UseQueryResult<TaskMetadata> => {
  return useQuery<TaskMetadata>({
    queryKey: ['useTaskMetadata', id], // data cached with unique id
    queryFn: () => fetchTokenInfo(id),
    enabled: isOpen,
  })
}

const fetchTokenInfo = async (id: string): Promise<TaskMetadata> => {
  if (!id) throw new Error('No id provided')
  
  const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL
  const url = `${BASE_URL}/relayer-executor/task-executions/${id}/metadata`

  try {
    const token = localStorage.getItem('token')

    const header = {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
      'x-auth-token': `${token}`,
    }

    const { data } = await axios.get<TaskMetadata>(url, { headers: header })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      try {
        await refresh()
        return await fetchTokenInfo(id)
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    throw error
  }
}

export default useTaskMetadata
