import styled from 'styled-components'
import moment from 'moment'
import Gas from './Gas'
import Relayer from './Relayer'
import Bot from './Bot'
import Activity from './Activity'
import { FlexRow, Card, H2 } from '../../../utils/styles'
import { formatDuration } from '../../../hooks/useCrons'
import Logs from './Logs'
import { ChainStatsResponse, CronStatusResponse } from '../../../types/API'
import { cronNames } from '../../../constants/cronNames'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const clock: string = require('../../../assets/clock.svg').default

export interface Props {
  chainId: number
  chainInfo: ChainStatsResponse
  crons?: CronStatusResponse[]
}


export default function Overview({ chainId, chainInfo, crons }: Props) {
  const cronName = cronNames[chainId] || ''
  const cronSelection = crons?.filter((cron) => cron.name.toLowerCase()?.includes(cronName))

  return (
    <>
      <br />
      <br />
      <FlexRow>
        {chainId && <Bot chainId={chainId} chainInfo={chainInfo} />}
        {chainId && <Relayer chainId={chainId} chainInfo={chainInfo} />}
        {chainId && <Gas chainId={chainId} />}
        {chainId && <Activity chainId={chainId} chainInfo={chainInfo} />}
      </FlexRow>
      <br />
      {cronSelection && (
        <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <span>
            <img src={clock} /> Cron status:
            {cronSelection.map((item) => (
              <>
                <span>
                  {' '}
                  {formatDuration(moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime))))}
                  {moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime))).asMinutes() < 2 &&
                  item.active !== true ? (
                    <> 🚨</>
                  ) : (
                    ''
                  )}
                </span>
                <span> | {moment(item.lastStartTime).format('DD/MM/YY HH:mm[hs]')}</span>
                <span>
                  {' '}
                  | {item.active === true ? 'Running 🏃‍♂️' : moment(item.lastCompletionTime).format('DD/MM/YY HH:mm[hs]')}
                </span>
              </>
            ))}
          </span>
        </div>
      )}
      <br />
      <H2>Recent Activity</H2>
      <Logs chainId={chainId} />
      <FlexRow>
        <TransactionCard>
          <p className="title">Last Hour Transactions</p>
          <FlexSeparator>
            <div>
              <h1>{chainInfo?.transactions ? chainInfo.transactions.success : '-'}</h1>
              <p>Success</p>
            </div>
            <div>
              <h1>{chainInfo?.transactions ? chainInfo.transactions.missing : '-'}</h1>
              <p>Missing</p>
            </div>
            <div>
              <h1>{chainInfo?.transactions ? chainInfo.transactions.pending : '-'}</h1>
              <p>Pending</p>
            </div>
            <div>
              <h1>{chainInfo?.transactions ? chainInfo.transactions.failed : '-'}</h1>
              <p>Failed</p>
            </div>
          </FlexSeparator>
        </TransactionCard>
      </FlexRow>
      <br />
      <br />
      <br />
    </>
  )
}


const FlexSeparator = styled.div`
  display: flex;
  align-items: center;
  div {
    border-right: solid 2px white;
    padding: 0 30px;
    @media only screen and (max-width: 700px) {
      border-right: solid 1px white;
      padding: 0px 15px;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
`

const TransactionCard = styled(Card)`
  width: 386px;
  height: 120px;
  @media only screen and (max-width: 700px) {
    h1 {
      text-align: center;
    }
  }
`
