import React, { useEffect, useState } from 'react'
import axios from 'axios'
import SignUpForm from './SignUpForm'
import CustomConfirmationModal from '../../components/CustomConfirmationModal'
import deleteIcon from '../../assets/delete.png'
import edit from '../../assets/edit.png'
import save from '../../assets/save.png'
import cancel from '../../assets/cancel.png'
import { refresh } from '../../utils/web3-utils'
import { ContainerTable, Flexbox, Right, ButtonViolet, Loader } from '../../utils/styles'
import toast, { Toaster } from 'react-hot-toast'
import FormModal from '../FormModal'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface User {
  _id: number
  email: string
  updatedAt: string
  roles: [string]
  password?: string
}

const UserList: React.FC = () => {
  const [users, setUsers] = useState<User[] | null>(null)
  const [editedProps, setEditedProps] = useState<User | any>(null)
  const [editItem, setEditItem] = useState<User | any>(null)
  const [editIndex, setEditIndex] = useState<number | any>(null)
  const [customModalOpen, setCustomModalOpen] = useState(false)
  const [deleteParams, setDeleteParams] = useState<any>('')
  const [addNew, setAddNew] = useState(false)

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${URL}/users`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      setUsers(response.data)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response?.status === 403) {
          toast('❗️You dont have permissions to view the user list')
        } else if (error.response.status === 401) {
          try {
            await refresh()
            await fetchUsers()
          } catch (refreshError) {
            console.error('Error: Unable to refresh token. Please log in again.')
          }
        }
        console.error('Error al obtener la lista de usuarios:', error)
      } else {
        console.error('Error al obtener la lista de usuarios:', error)
      }
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const handleEdit = (item: User, index: number) => {
    setEditIndex(index)
    setEditItem(item)
    setEditedProps(item)
  }

  const handleCancelEdit = () => {
    setEditIndex(null)
  }

  const handleSaveEdit = async () => {
    const token = localStorage.getItem('token')
    const url = `${URL}/users/${editedProps._id}`
    try {
      await axios.put(
        url,
        { ...editedProps },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-auth-token': `${token}`,
          },
        }
      )

      toast('User successfully updated')
      fetchUsers()
    } catch (error: any) {
      if (error.response?.status === 401) {
        try {
          await refresh()
          await handleSaveEdit()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
          toast('Error: Unable to refresh token. Please log in again.')
        }
      }

      console.error('There was an error updating this user item:', error)
      toast('There was an error updating this user item:' + error)
    }
    setEditIndex(null)
  }

  const handleDeleteClick = (item: any) => {
    setDeleteParams(item)
    setCustomModalOpen(true)
  }

  const handleConfirmDelete = async () => {
    const item = deleteParams
    const token = localStorage.getItem('token')
    const url = `${URL}/users/${item._id}`

    try {
      await axios.delete(url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      toast('User successfully deleted')
      fetchUsers()
    } catch (error: any) {
      if (error.response?.status === 403) {
        toast('❗️You dont have permissions to delete an user')
      } else if (error.response?.status === 401) {
        try {
          await refresh()
          await handleConfirmDelete()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
      console.error('There was an error deleting this user', error)
      toast('There was an error deleting this user: ' + error)
    }

    setCustomModalOpen(false)
  }

  const handleCancelDelete = () => {
    setCustomModalOpen(false)
  }

  const handleAddNewConfirm = () => {
    fetchUsers()
    setAddNew(false)
  }

  const handleAddNewCancel = () => {
    setAddNew(false)
  }

  return (
    <div>
      <Toaster position="top-right" />
      <Right>
        <ButtonViolet onClick={() => setAddNew(!addNew)}>+ Add user</ButtonViolet>
      </Right>
      {addNew && (
        <FormModal onConfirm={handleAddNewConfirm} onCancel={handleAddNewCancel}>
          <SignUpForm onSuccess={handleAddNewConfirm} />
        </FormModal>
      )}
      {users && users?.length > 0 ? (
        <ContainerTable className="editingTable">
          <thead>
            <tr>
              <th>User</th>
              <th>Roles</th>
              <th>Updated At</th>
              <th>Password</th>
              <th>Actions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user._id}>
                <td>
                  {editIndex === index ? (
                    <input
                      type="text"
                      value={editedProps?.email}
                      onChange={(e) =>
                        setEditedProps({
                          ...editedProps,
                          email: e.target.value,
                        })
                      }
                    />
                  ) : (
                    user.email
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      type="text"
                      className="x-large"
                      value={editedProps?.roles.join(',')}
                      onChange={(e) =>
                        setEditedProps({
                          ...editedProps,
                          roles: e.target.value.replace(/\s/g, '').split(','),
                        })
                      }
                    />
                  ) : (
                    user.roles.join(', ').replace(/,/g, ', ')
                  )}
                </td>
                <td>
                  {new Date(user.updatedAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      type="text"
                      value={editedProps?.password}
                      onChange={(e) =>
                        setEditedProps({
                          ...editedProps,
                          password: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <p>••••••</p>
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <Flexbox>
                      <img onClick={handleSaveEdit} src={save} alt="Save" />
                      <img onClick={handleCancelEdit} src={cancel} alt="Cancel" />
                    </Flexbox>
                  ) : (
                    <img onClick={() => handleEdit(user, index)} src={edit} alt="Edit" />
                  )}
                </td>
                <td>
                  <img onClick={() => handleDeleteClick(user)} src={deleteIcon} alt="Delete" />
                </td>
              </tr>
            ))}
          </tbody>
          {customModalOpen && (
            <CustomConfirmationModal
              message="Are you sure you want to delete this user?"
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}
        </ContainerTable>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default UserList
