import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { NextUIProvider } from '@nextui-org/react'
import { Route, Routes, Navigate, HashRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Login from './components/Login'
import Dashboard from './components/structure/Dashboard'
import ErrorAlert from './components/ErrorAlert'
import { SubgraphsProvider } from './context/SubgraphsContext'
import theme from './styles/theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: 1000,
    },
  },
})

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const closeError = () => setError(null)
  const showError = (message: string) => setError(message)
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (token) setIsLoggedIn(true)
  }, [])

  return (
    <HashRouter>
      <NextUIProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <SubgraphsProvider>
              {error && <ErrorAlert message={error} onClose={closeError} />}
              <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route
                  path="/login"
                  element={
                    isLoggedIn ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <Login onLogin={() => setIsLoggedIn(true)} showError={showError} />
                    )
                  }
                />
                <Route path="/dashboard/*" element={isLoggedIn || token ? <Dashboard /> : <Navigate to="/login" />} />
              </Routes>
            </SubgraphsProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </NextUIProvider>
    </HashRouter>
  )
}
