import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { refresh } from '../utils/web3-utils'

type TokenFilter = {
  chainId?: number
}

type SetState<T> = React.Dispatch<React.SetStateAction<T>>

export type TokenPrice = {
  address: string
  price: number
  date?: string
}

const useTokenPrices = (
  tokenList: string[],
  filter: TokenFilter,
  limit = 50,
  setLoadPage: SetState<number>,
  setTotalPages: SetState<number>
) => {
  return useQuery<TokenPrice[], Error>({
    queryKey: ['tokenPrices', tokenList?.toString(), filter.chainId],
    queryFn: () => fetchAllPrices(tokenList, filter, limit, setLoadPage, setTotalPages),
    enabled: tokenList?.length > 0,
  })
}

const fetchAllPrices = async (
  tokenList: string[],
  filter: TokenFilter,
  limit: number,
  setLoadPage: SetState<number>,
  setTotalPages: SetState<number>
): Promise<TokenPrice[]> => {
  let allData: TokenPrice[] = []

  // Function to split the tokenList into chunks of a given size
  const chunkArray = (array: string[], size: number): string[][] => {
    return array.reduce((acc, _, i) => (i % size ? acc : [...acc, array.slice(i, i + size)]), [] as string[][])
  }

  // Split the tokenList into chunks
  const tokenChunks = chunkArray(tokenList, limit)

  setTotalPages(tokenChunks.length)
  for (let i = 0 ; i < tokenChunks.length ; i++) {
    const chunk = tokenChunks[i]

    try {
      const data = await fetchPrices(chunk, filter, limit)
      setLoadPage(i + 1)
      if (data) {
        allData = allData.concat(data)
      }
    } catch (error) {
      console.error('dataTokenPrices Error fetching data for chunk', i, error)
    }
  }

  return allData
}

const fetchPrices = async (
  tokenList: string[],
  filter: TokenFilter,
  limit: number
): Promise<TokenPrice[]> => {
  if (!tokenList) return []
  const addresses = tokenList || []

  const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL
  const url = `${BASE_URL}/price-oracle/prices/last`

  const token = localStorage.getItem('token')

  const header = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': `${token}`,
  }

  try {
    const params = {
      addresses,
      limit,
      chainId: filter.chainId,
    }
    const { data } = await axios.get<TokenPrice[]>(url, {
      headers: header,
      params: { ...params },
    })
    return data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response?.status === 401) {
      try {
        await refresh()
        return await fetchPrices(tokenList, filter, limit) // Retry fetching prices after refresh
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    throw error
  }
}

export default useTokenPrices
