import styled from '@emotion/styled'
import React from 'react'

interface InputContainerProps {
  modal?: boolean;
}

const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  margin: 20px 0;

  input {
    background: transparent;
    border-radius: 10px;
    border: 2px solid #5f5d62;
    color: #fff;
    padding: 10px 10px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    min-width: 300px;
    @media only screen and (max-width: 700px) {
      min-width: 150px;
    }
    height: 34px;
    font-family: 'DMSans';
    font-size: 13px;

    &:focus {
      border-color: #0070f3;
      outline: none;
    }
  }

  label {
    position: absolute;
    top: 48%;
    left: 12px;
    transform: translateY(-160%);
    transition: transform 0.2s ease, color 0.2s ease, font-size 0.2s ease;
    color: #888 !important;
    background-color: ${({ modal }) => (modal ? '#323244' : '#202029')};
    padding: 0 4px;
    pointer-events: none;
    font-size: 12px;
    font-family: 'DMSansBold';
    line-height: 180%;
  }
`

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  modal?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({ label, modal = false, ...props }) => (
  <InputContainer modal={modal}>
    <input {...props} className="nextui-input" placeholder=" " />
    <label>{label}</label>
  </InputContainer>
)

export default CustomInput
