import { useContext } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import SubgraphsContext from '../context/SubgraphsContext'
import { Environment } from '../types/graphql'

const useSmartVault = (id: string, chainId: number): UseQueryResult<Environment | null> => {
  const subgraphs = useContext(SubgraphsContext)

  return useQuery<Environment | null>({
    queryKey: ['useSmartVault', id + chainId],
    queryFn: () => fetchSmartVault(chainId, id, subgraphs),
  })
}

const fetchSmartVault = async (chainId: number, id: string, subgraphs: Record<string, string>): Promise<Environment | null> => {
  try {
    if (!chainId) return null

    const data = await request<{ environment: Environment }>(
      subgraphs[chainId],
      gql`
        query SmartVault($id: String!) {
          environment(id: $id) {
            network
            smartVaults {
              id
              name
              registry
              implementation {
                id
                name
                deprecated
                stateless
              }
              relayerConfigs {
                balance
                relayer {
                  id
                }
                nativeToken {
                  decimals
                  id
                  name
                  symbol
                }
              }
            }
          }
        }
      `,
      { id: id.toLowerCase() }
    )

    return data.environment
  } catch (error) {
    console.error(`Error fetching smartVault for chain ${chainId}. ${subgraphs[chainId]}:`, error)
    return null
  }
}

export default useSmartVault
