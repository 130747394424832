import React, { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { ButtonViolet } from '../utils/styles'
import CustomInput from './general/Form'
const logo: string = require('../assets/MimicAdmin.svg').default
const URL = process.env.REACT_APP_SERVER_BASE_URL

interface LoginFormProps {
  onLogin: () => void
  showError: (message: string) => void
}

export default function Login({ onLogin, showError }: LoginFormProps) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const response = await axios.post(`${URL}/users/login`, {
        email,
        password,
      })
      localStorage.setItem('token', response.data.accessToken)
      localStorage.setItem('refreshToken', response.data.refreshToken)
      onLogin()
    } catch (error) {
      console.log(error)
      if (axios.isAxiosError(error) && error.response) {
        showError(error.response.data?.content?.message || error.response.data)
      } else {
        showError('Something went wrong :(')
      }
    }
  }

  return (
    <>
      <Navbar />
      <LoginSection>
        <Form onSubmit={handleSubmit}>
          <TitleM>Log in</TitleM>
          <CustomInput
            type="email"
            label="Email"
            name="Email"
            autoComplete="username"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            required
          />
          <CustomInput
            type="password"
            label="Password"
            name="Password"
            autoComplete="current-password"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            required
          />
          <ButtonViolet type="submit">Login</ButtonViolet>
        </Form>
      </LoginSection>
    </>
  )
}

const LoginSection = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Form = styled.form`
  display: block;
  padding: 40px 200px;
  border-radius: 20px;
  input {
    display: block;
    margin-bottom: 20px;
    width: 300px;
    height: 40px;
    padding: 0 10px;
    border-radius: 7px;
    border: 0px;
    font-family: 'DMSans';
    font-size: 15px;
  }
`

const TitleM = styled.h1`
  color: white;
  font-size: 24px;
  margin: 0;
  font-family: 'Visby';
  font-style: normal;
  line-height: 120%;
  font-size: 40px;
  margin-bottom: 50px;
  @media only screen and (max-width: 800px) {
    font-size: 32px;
  }
`

export function Navbar() {
  return (
    <NavbarSection>
      <NavbarContainer>
        <img alt="logo" src={logo} />
        <NavbarLink>
          Learn more in{' '}
          <a target="_blank" href="https://mimic.fi" rel="noreferrer">
            Mimic.fi
          </a>
        </NavbarLink>
      </NavbarContainer>
    </NavbarSection>
  )
}

const NavbarSection = styled.section`
  z-index: 100;
  width: 100%;
  margin: auto;
  position: sticky;
  background: #12141a;
`

const NavbarContainer = styled.div`
  margin: 0 32px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding 0;
`

const NavbarLink = styled.div`
  color: #a5a1b7;
  font-feature-settings:
    'clig' off,
    'liga' off;
  a {
    font-weight: 700;
    color: #5ce6d5;
    font-family: 'DMSansBold';
  }
`
