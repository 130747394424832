import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { SimulationsResponse } from '../types/API'

const fetchSimulations = async (id: string, startDate: Date | null, endDate: Date | null, tasks: string[], chainId: string): Promise<SimulationsResponse> => {
  const token = localStorage.getItem('token')
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/relayer-executor/environments/${id}/simulations`

  const params = {
    chainIds: [chainId],
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    tasks,
  }

  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': token || '',
  }

  const response = await axios.get<SimulationsResponse>(url, { params, headers })
  return response.data
}

const useSimulations = (id: string | undefined, startDate: Date | null, endDate: Date | null, tasks: string[], chainId: string) => {
  return useQuery<SimulationsResponse>({
    queryKey: ['simulations', id, startDate, endDate, tasks, chainId],
    queryFn: () => fetchSimulations(id!, startDate, endDate, tasks, chainId),
    enabled: !!id && !!startDate && !!endDate,
  })
}

export default useSimulations
