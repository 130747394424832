import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ApiSettings from '../components/settings/config/ApiSettings'
import Web3Settings from '../components/settings/config/Web3Settings'
import TokenRegistry from '../components/settings/config/TokenRegistry'
import Notifications from '../components/settings/config/Notifications'
import RelayerExecutorForm from '../components/settings/config/RelayerExecutorForm'
import Users from '../components/settings/UserList'
import Crons from '../components/settings/Crons'
import Subgraphs from '../components/settings/Subgraphs'
import Logs from '../components/settings/Logs'
import Registry from '../components/settings/Registry'
import Networks from '../components/settings/network/NetworksPage'
import Rpcs from '../components/settings/network/Rpcs'

import Pods from '../components/settings/Pods'
import { PriceOracleSettings } from '../components/settings/config/PriceOracle'
import { Tab, H2, H1, Section } from '../utils/styles'
import styled from 'styled-components'

export default function Tabs() {
  const [activeTab, setActiveTab] = useState('config')
  const navigate = useNavigate()
  const { tab } = useParams()

  useEffect(() => {
    if (tab) {
      setActiveTab(tab)
    }
  }, [tab])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
    navigate(`/dashboard/settings/${tab}`)
  }

  return (
    <Section>
      <H1>General</H1>
      <Tab>
        <button onClick={() => handleTabClick('config')} className={activeTab === 'config' ? 'active' : ''}>
          Config
        </button>
        <button onClick={() => handleTabClick('registry')} className={activeTab === 'registry' ? 'active' : ''}>
          Registry
        </button>
        <button onClick={() => handleTabClick('users')} className={activeTab === 'users' ? 'active' : ''}>
          Users
        </button>
        <button onClick={() => handleTabClick('networks')} className={activeTab === 'networks' ? 'active' : ''}>
          Networks
        </button>
        <button onClick={() => handleTabClick('rpcs')} className={activeTab === 'rpcs' ? 'active' : ''}>
          RPCs
        </button>
        <button onClick={() => handleTabClick('subgraphs')} className={activeTab === 'subgraphs' ? 'active' : ''}>
          Subgraphs
        </button>
        <button onClick={() => handleTabClick('crons')} className={activeTab === 'crons' ? 'active' : ''}>
          Crons
        </button>
        <button onClick={() => handleTabClick('pods')} className={activeTab === 'pods' ? 'active' : ''}>
          Pods
        </button>
        <button onClick={() => handleTabClick('logs')} className={activeTab === 'logs' ? 'active' : ''}>
          Logs
        </button>
      </Tab>
      <div>
        {activeTab === 'config' && <Settings />}
        {activeTab === 'registry' && <Registry />}
        {activeTab === 'users' && <Users />}
        {activeTab === 'networks' && <Networks />}
        {activeTab === 'rpcs' && <Rpcs />}
        {activeTab === 'subgraphs' && <Subgraphs />}
        {activeTab === 'crons' && <Crons />}
        {activeTab === 'pods' && <Pods />}
        {activeTab === 'logs' && <Logs />}
      </div>
    </Section>
  )
}

function Settings() {
  return (
    <div>
      <H2>Relayer Executor</H2>
      <RelayerExecutorForm />
      <br />
      <br />
      <H2>Web3</H2>
      <Web3Settings />
      <br />
      <br />
      <H2>Price Oracle</H2>
      <PriceOracleSettings />
      <br />
      <br />
      <H2>Token Registry</H2>
      <TokenRegistry />
      <br />
      <br />
      <H2>Notifications</H2>
      <Notifications />
      <br />
      <br />
      <H2>API</H2>
      <ApiSettings />
    </div>
  )
}
