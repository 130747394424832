import axios from 'axios'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { refresh } from '../utils/web3-utils'
import { TaskPlanResponse } from '../types/API'

const useTaskPlanId = (id: string, isOpen: boolean): UseQueryResult<TaskPlanResponse> => {
  return useQuery<TaskPlanResponse>({
    queryKey: ['useTaskPlanId', id],
    queryFn: () => fetchTokenInfo(id),
    enabled: isOpen,
  })
}

const fetchTokenInfo = async (id: string): Promise<TaskPlanResponse> => {
  if (!id) throw new Error('No id provided')
  
  const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL

  const url = `${BASE_URL}/relayer-executor/task-executions/${id}/plan`

  const token = localStorage.getItem('token')

  const header = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': `${token}`,
  }

  try {
    const { data } = await axios.get<TaskPlanResponse>(url, { headers: header })
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      try {
        await refresh()
        return await fetchTokenInfo(id)
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    throw error
  }
}

export default useTaskPlanId
