import { useQuery } from '@tanstack/react-query'
import { EcoModesResponse } from 'API'
import axios from 'axios'
import { formatTokenAmount } from '../utils/math-utils'
import { refresh } from '../utils/web3-utils'

const URL = process.env.REACT_APP_SERVER_BASE_URL
const AUTH_TOKEN = localStorage.getItem('token')

async function fetchNetworkInfo(chainId: number, id: string): Promise<EcoModesResponse[]> {
  try {
    const response = await axios.get(`${URL}/relayer-executor/environments/${id}/eco-modes`, {
      params: {
        chainId: chainId,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'x-auth-token': `${AUTH_TOKEN}`,
      },
    })
    return response.data
  } catch (error) {
    if ((error as any).response?.status === 401) {
      try {
        await refresh()
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    console.error('Error fetching ecoModes:', error)
    throw error
  }
}

function useEcoModes(chainId: number, id: string) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['useEcoModes', id + chainId + 'eco'],
    queryFn: () => fetchNetworkInfo(chainId, id),
  })

  return { data, isLoading, error, refetch }
}

export default useEcoModes
