import axios from 'axios'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { refresh } from '../utils/web3-utils'
import { ChainResponse } from '../types/API'

const URL = process.env.REACT_APP_SERVER_BASE_URL

const useNetworkHeader = (id: string, chainId: string): UseQueryResult<ChainResponse | undefined> => {
  return useQuery<ChainResponse | undefined>({
    queryKey: ['networkHeader', id, chainId],
    queryFn: () => fetchNetwork(id, chainId),
  })
}

const fetchNetwork = async (id: string, chainId: string): Promise<ChainResponse | undefined> => {
  if (!chainId) return undefined

  const token = localStorage.getItem('token')
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': `${token}`,
  }

  try {
    const response = await axios.get<ChainResponse[]>(`${URL}/relayer-executor/chains`, {
      headers: headers,
    })

    const chainsInfo = response.data.find((object) => object.chainId === chainId)
    return chainsInfo
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      try {
        await refresh()
        return await fetchNetwork(id, chainId)
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    } else {
      console.error('Error fetching network:', error)
    }
    return undefined
  }
}

export default useNetworkHeader
