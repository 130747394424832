// @ts-nocheck
import React, { useState, useRef, useEffect, FC } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import useLogs from '../../../hooks/useLogs'
import { SupportedChainIdValue } from '../../../constants/chainInfo'
import { StickyTable, ContainerTable, Loader } from '../../../utils/styles'
import Select from 'react-select'
import LogsItem from '../logs/LogItem'

interface LogsProps {
  chainId: number
}

interface Filter {
  token?: string
  status?: string
  executionPlanId?: string
  chainId?: SupportedChainIdValue | number
}

interface TaskStatus {
  type: string // Assuming type is a string, adjust as necessary
}

interface Task {
  executionType?: string // The question mark denotes that the property might be undefined
  name: string
}

interface ItemTask {
  id: string
  task?: Task
  status?: TaskStatus
  executedAt?: number // Assuming executedAt is a unix timestamp (number)
  chainId?: SupportedChainIdValue
  planId?: string
  index?: number // Assuming index is a number, adjust as necessary
}



const Logs: FC<LogsProps> = ({ chainId }) => {
  const params = useParams<{ id: string }>() // Specify the type of useParams
  const buttonRef = useRef<HTMLSelectElement>(null)
  const [filters, setFilters] = useState<Filter>({ status: ['executionSucceeded'], chainId })
  const { data, isLoading, isRefetching } = useLogs(params.id, 1, 70, filters, 0)

  const navigate = useNavigate()
  const location = useLocation()

  const updateURL = (newFilters) => {
    const searchParams = new URLSearchParams()

    Object.keys(newFilters).forEach((key) => {
      const value = newFilters[key]
      if (value !== undefined) {
        if (Array.isArray(value)) {
          // Correctly handle array values
          value.forEach((v) => {
            searchParams.append(`${key}[]`, String(v)) // Use append for arrays
          })
        } else {
          // Handle non-array values
          searchParams.set(key, String(value)) // Convert numbers/booleans to strings
        }
      } else {
        // Remove the parameter if the value is undefined
        searchParams.delete(key)
      }
    })

    // For React Router v6
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
  }

  // Adjust your useEffect hook like so:
  useEffect(() => {
    setFilters({ status: ['executionSucceeded'], chainId })
    updateURL({ token: '', status: ['executionSucceeded'], executionPlanId: '', chainId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId])


  return (
    <div>
      <div>
        {isLoading ? (
          <Loader />
        ) : data ? (
          <>
            <StickyTable>
              <ContainerTable>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Network</th>
                    <th>Task</th>
                    <th>Status</th>
                    <th>Details</th>
                    <th>PlanId #i</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data
                    ?.slice(0, 10)
                    .map((task: ItemTask, i: number) => (
                      <LogsItem
                        key={i}
                        item={task}
                        index={i + 1}
                        width={1200}
                        colored={false}
                        handleSelectPlanId={() => {}}
                      />
                    ))}
                </tbody>
              </ContainerTable>
            </StickyTable>
          </>
        ) : (
          <>ups...</>
        )}
      </div>
      <br />
      <br />
    </div>
  )
}

export default Logs
