import { useQuery } from '@tanstack/react-query'
import { QueueExecutionsResponse } from 'API'
import axios from 'axios'
import { refresh } from '../utils/web3-utils'

export interface QueueExecutionsFilters {
  mimicId?: string
  chainId?: number
  status?: string
  order?: 'asc' | 'desc'
  limit?: number
  page?: number
}

const URL = process.env.REACT_APP_SERVER_BASE_URL
const AUTH_TOKEN = localStorage.getItem('token')

async function fetchQueueExecutions(params: QueueExecutionsFilters): Promise<QueueExecutionsResponse[]> {
  try {
    const response = await axios.get(`${URL}/relayer-executor/queue-executions`, {
      params,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'x-auth-token': `${AUTH_TOKEN}`,
      },
    })
    return response.data
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((error as any).response?.status === 401) {
      try {
        await refresh()
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    console.error('Error fetching queue executions:', error)
    throw error
  }
}

function useQueueExecutions(params: QueueExecutionsFilters) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['queueExecutions', ...Object.values(params)],
    queryFn: () => fetchQueueExecutions(params),
  })

  return { data, isLoading, error, refetch }
}

export default useQueueExecutions
