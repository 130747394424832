import { useQuery } from '@tanstack/react-query'
import { NetworkResponse } from 'API'
import axios from 'axios'
import { formatTokenAmount } from '../utils/math-utils'
import { refresh } from '../utils/web3-utils'

const URL = process.env.REACT_APP_SERVER_BASE_URL
const AUTH_TOKEN = localStorage.getItem('token')

async function fetchNetworkInfo(chainId: number): Promise<NetworkResponse> {
  try {
    const chains = await axios.get(`${URL}/relayer-executor/chains`, {
      params: {
        chainId: chainId,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'x-auth-token': `${AUTH_TOKEN}`,
      },
    })
    const chainsInfo = chains?.data?.find((objet: NetworkResponse) => objet.chainId === chainId)
    return chainsInfo
  } catch (error) {
    if ((error as any).response?.status === 401) {
      try {
        await refresh()
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    console.error('Error fetching network info:', error)
    throw error
  }
}

function useNetworkInfo(chainId: number) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['networkInfo', chainId],
    queryFn: () => fetchNetworkInfo(chainId),
  })

  return { data, isLoading, error, refetch }
}

export default useNetworkInfo
