import React from 'react'
import styled from 'styled-components'

interface SwitchProps {
  ison: boolean
  onToggle: () => void
}

const StyledSwitch = styled.div<{ ison: string }>`
  width: 32px;
  height: 16px;
  background-color: ${({ ison }) => (ison === 'true' ? '#5CE6D5' : '#F94872')};
  border-radius: 15px;
  position: relative;
  cursor: pointer;
`

const SwitchHandle = styled.div<{ ison: string }>`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  transition: transform 0.3s ease;
  transform: ${({ ison }) => (ison === 'true' ? 'translateX(16px)' : 'translateX(0)')};
`

const Switch: React.FC<SwitchProps> = ({ ison, onToggle }) => {
  return (
    <StyledSwitch ison={ison?.toString()} onClick={onToggle}>
      <SwitchHandle ison={ison?.toString()} />
    </StyledSwitch>
  )
}

export default Switch
