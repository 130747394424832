import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import toast, { Toaster } from 'react-hot-toast'
import { ContainerTable, Flexbox, Loader, ButtonViolet } from '../../utils/styles'
import { refresh } from '../../utils/web3-utils'
import Calendar from '../Calendar'

interface Report {
  startDate: string
  createdAt: string
  endDate: string
  updatedAt: string
  fileName: string
  mimicId: string
  _id: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL
const DATE_FORMAT = 'MMMM DD, YYYY, HH:mm'

const Reports: React.FC = () => {
  const [data, setReports] = useState<Report[] | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const params = useParams()

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date)
  }

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date)
  }

  const fetchReport = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get<Report[]>(`${URL}/reports/environments/${params.id}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      setReports(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          try {
            await refresh()
            await fetchReport()
          } catch (refreshError) {
            console.error('Error: Unable to refresh token. Please log in again.')
          }
        }
        console.error('Token list error:', error)
      } else {
        console.error('An unexpected error occurred:', error)
      }
    }
  }

  useEffect(() => {
    fetchReport()
  }, [])

  const handleDownload = async (id: string, fileName: string) => {
    const token = localStorage.getItem('token')
    const url = `${URL}/reports/environments/${id}/download`

    try {
      const response = await axios.get(url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'x-auth-token': `${token}`,
        },
        responseType: 'blob',
      })
      saveAs(new Blob([response.data]), fileName)
      toast.success('✅ Your report was successfully downloaded')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 403) {
          toast("❗️You don't have permissions to download this report")
        } else if (error?.response?.status === 401) {
          try {
            await refresh()
            await handleDownload(id, fileName)
          } catch (refreshError) {
            console.error('Error: Unable to refresh token. Please log in again.')
          }
        } else {
          toast("❗️We couldn't download the report")
        }
      } else {
        console.error('There was an error trying to download the report:', error)
      }
    }
  }

  const generateReport = async () => {
    const token = localStorage.getItem('token')
    const url = `${URL}/jobs/report`

    if (!startDate || !endDate) {
      toast('❗️You have to complete both dates before genersting the report')
    } else {
      const adjustedStartDate = moment.utc(startDate).set({
        hour: startDate.getHours(),
        minute: startDate.getMinutes(),
        second: 0,
        millisecond: 0,
      }).toISOString();
      console.log('adjusted? ')

      const adjustedEndDate = moment.utc(endDate).set({
        hour: endDate.getHours(),
        minute: endDate.getMinutes(),
        second: 0,
        millisecond: 0,
      }).toISOString();

      try {
        await axios.post(
          url,
          {
            startDate: adjustedStartDate,
            endDate: adjustedEndDate,
            mimicId: params.id,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'x-auth-token': `${token}`,
            },
          }
        )
        fetchReport()
        toast('✅ Your report was successfully generated')
      } catch (error: any) {
        if (error?.response?.status === 403) {
          toast('❗️You dont have permissions to create a report')
        } else if (error?.response?.status === 401) {
          try {
            await refresh()
            await generateReport()
          } catch (refreshError) {
            console.error('Error: Unable to refresh token. Please log in again.')
          }
        } else {
          toast('❗️We couldnt generate the report. Try again later')
        }
        console.error('There was an error generating the report:', error)
      }
    }
  }

  return (
    <ReportSection>
      <Toaster position="top-right" />
      <>
        <Flex>
          <Calendar label="From date" selectedDate={startDate} withHour={true} onChange={handleStartDateChange} />
          <Calendar label="To date" selectedDate={endDate} withHour={true} onChange={handleEndDateChange} />
          <ButtonViolet onClick={generateReport}>Generate Report</ButtonViolet>
        </Flex>
      </>
      {data ? (
        <>
          <ContainerTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>From (UTC)</th>
                <th>To (UTC)</th>
                <th>Created At (UTC)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="accent">{item.fileName}</td>
                  <td>{moment.utc(item.startDate).format(DATE_FORMAT)} hs</td>
                  <td>{moment.utc(item.endDate).format(DATE_FORMAT)} hs</td>
                  <td>{moment.utc(item.createdAt).format(DATE_FORMAT)} hs</td>
                  <td>
                    <button className="violet" onClick={() => handleDownload(item._id, item.fileName)}>
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </ContainerTable>
        </>
      ) : (
        <Loader />
      )}
    </ReportSection>
  )
}

const ReportSection = styled.div`
  margin: 50px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 874px;
  max-width: 90%;
  table {
    input {
      margin-bottom: 0 !important;
      height: 26px !important;
    }
  }
`

const Flex = styled(Flexbox)`
  align-items: center;
  justify-content: center;
  button {
    margin-top: 0;
  }
`

export default Reports
