import React from 'react'
import styled from 'styled-components'
import { ContainerTable, StickyTable, Loader } from '../../utils/styles'
import { getEtherscanLink } from '../../utils/web3-utils'
import Network from '../general/Network'
import Address from '../general/Address'
import { SupportedChainId } from '../../constants/chainInfo'
import useRegistry from '../../hooks/useRegistry'

const StatusRelayer: React.FC = () => {
  const response = useRegistry()

  return (
    <div>
      {response.data ? (
        <>
          <StickyTable>
            <ContainerTable>
              <thead>
                <tr>
                  <th>Name</th>
                  {Object.entries(SupportedChainId).map(([key, value]) => (
                    <th key={key}>
                      <Network network={value} noLogo={true} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.entries(response.data)
                  .sort(([, itemsA], [, itemsB]) => itemsA[0].name.localeCompare(itemsB[0].name))
                  .map(
                    ([key1, items]) =>
                      items[0].name && (
                        <tr key={key1}>
                          <td className="accent">
                            {items[0].name}
                            {items[0].stateless && <span className="warning"> Stateless</span>}
                          </td>
                          {Object.values(SupportedChainId).map((chainId) => {
                            const chainData = items.find((i: any) => i.chainId === chainId.toString())
                            return chainData ? (
                              <React.Fragment key={chainId}>
                                <td>
                                  {chainData.deprecated ? (
                                    <a
                                      href={getEtherscanLink(chainId, key1, 'address')}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="error"
                                    >
                                      Deprecated
                                    </a>
                                  ) : (
                                    <Address
                                      address={key1}
                                      short={true}
                                      withLink={true}
                                      chainId={chainId}
                                      type={'address'}
                                      showIdentity={false}
                                    />
                                  )}{' '}
                                  <br />
                                </td>
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={chainId}>
                                <td></td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      )
                  )}
              </tbody>
            </ContainerTable>
          </StickyTable>
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default StatusRelayer
