import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import { ContainerTable, Loader } from '../utils/styles'
import { refresh } from '../utils/web3-utils'
import { ButtonViolet } from '../utils/styles'

interface LogGroups {
  creationTime: number
  arn: string
  firstEventTimestamp: number
  lastEventTimestamp: number
  lastIngestionTime: number
  logStreamName: string
  uploadSequenceToken: string
  storedBytes: number
}
interface Data {
  logStreams: LogGroups[]
  nextToken: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const LogsStreams: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [next, setNext] = useState<string | null>(null)
  const name = window.location.href.split('/groups?name=')
  const [logsGroups, setLogsStreams] = useState<LogGroups[] | null>(null)
  const [button, setButton] = useState({ disabled: false, message: 'See more' })

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token')

      const response = await axios.get<Data>(`${URL}/logs/${name[1]}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      setLogsStreams(response.data?.logStreams)
      setNext(response.data?.nextToken || null)
    } catch (error: any) {
      if (error.response?.status === 401) {
        try {
          await refresh()
          await fetchData()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
      console.error('Token list error:', error)
    }
  }

  const handleSeeMore = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get<Data>(`${URL}/logs/${name[1]}`, {
        params: {
          nextToken: next,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })

      if (response.data?.logStreams && response.data?.logStreams.length > 0) {
        setLogsStreams(logsGroups ? logsGroups.concat(response.data?.logStreams) : null)
      } else if (response?.data?.logStreams?.length === 0) {
        setButton({ disabled: true, message: 'No more logs' })
      }
    } catch (error) {
      console.error('There was an error with the web3 form:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleRowClick = (id: string) => {
    navigate(`${location.pathname.split('logs/groups')[0]}logs/${id}/groups?name=${name[1]}`, {
      replace: true,
    })
  }

  return (
    <EcoModeSection>
      {logsGroups ? (
        <>
          <ContainerTable>
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {logsGroups.map((item, index) => (
                <tr key={index} onClick={() => handleRowClick(item.logStreamName)}>
                  <td>{item.logStreamName}</td>
                </tr>
              ))}
            </tbody>
          </ContainerTable>
          {next && (
            <ButtonViolet onClick={handleSeeMore} disabled={button.disabled}>
              {button.message}
            </ButtonViolet>
          )}
        </>
      ) : (
        <Loader />
      )}
    </EcoModeSection>
  )
}

const EcoModeSection = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 874px;
  max-width: 90%;
  td {
    cursor: pointer;
  }
  button:disabled,
  button[disabled] {
    background-color: #cccccc;
    color: #666666;
  }
`

export default LogsStreams
