import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'

interface SubgraphStatus {
  subgraph: string;
  synced: boolean;
  fatalError?: {
    message: string;
  };
  nonFatalErrors?: {
    message: string;
  }[];
  chainId: string;
}

const subgraphsName: Record<number, string> = {
  43114: 'mimic-fi/v3-arbitrum',
  1: 'mimic-fi/v3-mainnet',
  137: 'mimic-fi/v3-polygon',
  56: 'mimic-fi/v3-bsc',
  42161: 'mimic-fi/v3-avalanche',
  10: 'mimic-fi/v3-optimism',
  100: 'mimic-fi/v3-gnosis',
  1313161554: 'mimic-fi/v3-aurora',
  250: 'mimic-fi/v3-fantom',
}

const useSubgraphs = (): UseQueryResult<SubgraphStatus[]> => {
  return useQuery<SubgraphStatus[]>({
    queryKey: ['useSubgraphs'],
    queryFn: () => fetchAllTasks(),
  })
}

const fetchSubgraph = async (chainId: number): Promise<SubgraphStatus | null> => {
  try {
    const data = await request<{ indexingStatusForCurrentVersion: SubgraphStatus }>(
      'https://api.thegraph.com/index-node/graphql',
      gql`
        query Subgraphs($subgraphName: String!) {
          indexingStatusForCurrentVersion(subgraphName: $subgraphName) {
            subgraph
            synced
            fatalError {
              message
            }
            nonFatalErrors {
              message
            }
          }
        }
      `,
      { subgraphName: subgraphsName[chainId] }
    )
    return { ...data.indexingStatusForCurrentVersion, chainId: chainId.toString() }
  } catch (error) {
    console.error(`Error fetching subgraph for chain ${chainId}:`, error)
    return null
  }
}

const fetchAllTasks = async (): Promise<SubgraphStatus[]> => {
  const allData: SubgraphStatus[] = []
  const chainIds = Object.keys(subgraphsName).map(Number)

  for (const chainId of chainIds) {
    const configs = await fetchSubgraph(chainId)
    if (configs) {
      allData.push(configs)
    }
  }

  return allData
}

export default useSubgraphs
