import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { VolumeResponse } from '../types/API'

const fetchVolume = async (id: string, startDate: Date | null, endDate: Date | null, tasks: string[], chainId: string): Promise<VolumeResponse> => {
  const token = localStorage.getItem('token')
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/relayer-executor/environments/${id}/volume`

  const params = {
    chainIds: [chainId],
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    tasks,
  }

  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': token || '',
  }

  const response = await axios.get<VolumeResponse>(url, { params, headers })
  return response.data
}

const useVolume = (id: string | undefined, startDate: Date | null, endDate: Date | null, tasks: string[], chainId: string) => {
  return useQuery<VolumeResponse>(
    {
      queryKey: ['volume', id, startDate, endDate, tasks, chainId],
      queryFn: () => fetchVolume(id!, startDate, endDate, tasks, chainId),
      enabled: !!id && !!startDate && !!endDate,
    }
  )
}

export default useVolume
