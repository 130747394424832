import axios from 'axios'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { refresh } from '../utils/web3-utils'
import { TransactionResponse } from '../types/API'

const useTaskData = (id: string, isOpen: boolean): UseQueryResult<TransactionResponse> => {
  return useQuery<TransactionResponse>({
    queryKey: ['taskData', id], // data cached with unique id
    queryFn: () => fetchTokenInfo(id),
    enabled: isOpen,
  })
}

const fetchTokenInfo = async (id: string): Promise<TransactionResponse> => {
  if (!id) throw new Error('No id provided')

  const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL
  const url = `${BASE_URL}/relayer-executor/task-executions/${id}/transaction`

  try {
    const { data } = await axios.get<TransactionResponse>(url)
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      try {
        await refresh()
        return await fetchTokenInfo(id)
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    }
    throw error
  }
}

export default useTaskData
