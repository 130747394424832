import React, { useState, useEffect } from 'react'
import axios from 'axios'
import bg from '../../../assets/bg.png'
import { refresh } from '../../../utils/web3-utils'
import { parseName } from '../../../utils/math-utils'
import { ButtonViolet, ButtonWhite, Message } from '../../../utils/styles'
import CustomInput from '../../general/Form'
import { Form } from '../../../utils/typescript-styles'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface RelayerExecutorFormProps {
  onSuccess?: () => void
}

interface RelayerData {
  _id: string
  rateLimitRequests: number
  rateLimitWindowMs: number
  corsAllowedOrigins: string[]
}

const RelayerExecutorForm: React.FC<RelayerExecutorFormProps> = ({ onSuccess = () => {} }) => {
  const [message, setMessage] = useState('')
  const [relayerData, setRelayerData] = useState<RelayerData | null>(null)
  const [editedSettings, setEditedSettings] = useState<Partial<RelayerData> | null>(null)

  useEffect(() => {
    const fetchApiSettings = async () => {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.get(`${URL}/web3/settings`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'application/json',
            'x-auth-token': `${token}`,
          },
        })
        setRelayerData(response.data)
        setEditedSettings(response.data)
      } catch (error: any) {
        if (error.response.status === 401) {
          try {
            await refresh()
            await fetchApiSettings()
          } catch (refreshError) {
            console.error('Error: Unable to refresh token. Please log in again.')
          }
        }
        console.error('There was an error with relayer executor form data:', error)
      }
    }

    fetchApiSettings()
  }, [])

  const handleCancelEditClick = () => {
    setEditedSettings(relayerData)
  }

  const handleSaveClick = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const token = localStorage.getItem('token')
      await axios
        .put(`${URL}/web3/settings`, editedSettings, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'application/json',
            'x-auth-token': `${token}`,
          },
        })
        .then((response) => {
          setMessage('The form has been successfully updated')
        })
        .catch((error) => {
          setMessage('There was an error')
        })
    } catch (error) {
      console.error('There was an error with the web3 form:', error)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setEditedSettings((prevSettings: any) => {
      const updatedValue = e.target.type === 'number' ? parseFloat(value) : value

      return {
        ...prevSettings,
        [name]: updatedValue,
      }
    })
  }

  return (
    <Form onSubmit={handleSaveClick}>
      {message !== '' ? (
        <Message>
          <span>{message}</span>
          <span className="close" onClick={() => setMessage('')}>
            X
          </span>
        </Message>
      ) : (
        <>
          <div className="container">
            {editedSettings &&
              Object.entries(editedSettings as RelayerData).map(
                ([key, value]) =>
                  key !== '__v' &&
                  key !== 'createdAt' &&
                  key !== 'updatedAt' &&
                  key !== 'lastUpdate' &&
                  key !== '_id' && (
                    <div key={key} className="input-group">
                      <CustomInput
                        type={typeof value === 'number' ? 'number' : 'text'}
                        name={key}
                        label={parseName(key)}
                        value={typeof value === 'number' ? value : String(value)}
                        onChange={handleInputChange}
                        disabled={key === '_id'}
                      />
                    </div>
                  )
              )}
          </div>
          <div className="buttons">
            <ButtonViolet type="submit">Save</ButtonViolet>
            <ButtonWhite className="white" onClick={handleCancelEditClick}>
              Cancel
            </ButtonWhite>
          </div>
        </>
      )}
    </Form>
  )
}

export default RelayerExecutorForm
