import React from 'react'
import { Route, Routes, useLocation, Navigate, NavLink } from 'react-router-dom'
import NavBar from './NavBar'
import Tokens from '../../pages/Tokens'
import QueueExecutions from '../../pages/QueueExecutions'
import Settings from '../../pages/Settings'
import styled from 'styled-components'
import EnvironmentLogo from '../../assets/sidebar/Environment'
import TokensLogo from '../../assets/sidebar/Tokens'
import ConfigLogo from '../../assets/sidebar/Config'
import EnvironmentsList from '../../pages/EnvironmentsList'
import LogsGroup from '../../pages/LogsGroup'
import LogsItem from '../../pages/LogsItem'
import Environment from '../../components/environment/Environment'
import QueueLogo from '../../assets/sidebar/Queue'

export default function Dashboard() {
  const location = useLocation()
  return (
    <DashboardSection>
      <NavBar />
      <Content>
        <SideBarContainer>
          <div>
            <NavItem
              to="/dashboard/environments"
              name="/environments"
              logo={<EnvironmentLogo fill={location.pathname.includes('/environments') ? '#5CE6D5' : '#96969C'} />}
            />
            <NavItem
              to="/dashboard/tokens"
              name="/tokenst"
              logo={<TokensLogo fill={location.pathname.includes('/tokens') ? '#5CE6D5' : '#96969C'} />}
            />
            <NavItem
              to="/dashboard/queue-executions"
              name="/queue-executions"
              logo={<QueueLogo fill={location.pathname.includes('/queue-executions') ? '#5CE6D5' : '#96969C'} />}
            />
            <NavItem
              to="/dashboard/settings"
              name="/settings"
              logo={<ConfigLogo fill={location.pathname.includes('/settings') ? '#5CE6D5' : '#96969C'} />}
            />
          </div>
        </SideBarContainer>
        <DashboardContainer>
          <div className="dashboard-content">
            <Routes>
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/:id" element={<Settings />} />
              <Route path="/settings/logs/groups" element={<LogsGroup />} />
              <Route path="/settings/logs/:id/groups" element={<LogsItem />} />
              <Route path="/queue-executions" element={<QueueExecutions />} />
              <Route path="/tokens" element={<Tokens />} />
              <Route path="/tokens/:tab" element={<Tokens />} />
              <Route path="/environments" element={<EnvironmentsList />} />
              <Route path="/environments/:id" element={<Environment />} />
              <Route path="/environments/:id/:tab" element={<Environment />} />
              <Route path="/" element={<Navigate to="/dashboard/environments" replace />} />
            </Routes>
          </div>
        </DashboardContainer>
      </Content>
    </DashboardSection>
  )
}

interface NavItemProps {
  to: string
  name: string
  logo?: React.ReactElement
}

const NavItem: React.FC<NavItemProps> = ({ to, name = '', logo }) => {
  const location = useLocation()
  const isActive = location.pathname.includes(name)

  return (
    <NavButton className={`nav-item ${isActive ? 'active' : ''}`}>
      <NavLink to={to} className="nav-link">
        {logo}
      </NavLink>
    </NavButton>
  )
}

const DashboardSection = styled.div`
  height: 100vh;
`
const SideBarContainer = styled.div`
  background: ${(props) => props.theme.backgroundBelow};
  width: 80px;
  display: flex;
  padding-top: 100px;
  justify-content: center;

  @media only screen and (max-width: 700px) {
    width: 100%;
    height: 80px;
    padding: 0;
    border-bottom: 1px #979797 solid;
    div {
      display: flex;
      align-items: center;
    }
  }
`

const NavButton = styled.div`
  padding: 18px;
  border-radius: 16px;
  &.active {
    background: ${(props) => props.theme.backgroundSurface};
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  @media only screen and (max-width: 700px) {
    display: block;
  }
`

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${(props) => props.theme.background};
  padding: 30px 0;
  min-height: calc(100vh - 80px);
  width: calc(100vw - 80px);
  @media only screen and (max-width: 700px) {
    width: 100vw;
    padding: 30px 15px;
    box-sizing: border-box;
  }

  .dashboard-content {
    width: calc(100vw - 80px);
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }

  h2 {
    font-size: 30px;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .green {
    color: #33c2b0;
  }
  .yellow {
    color: #ffcc33;
  }
  .orange {
    color: #ff8927;
  }
  .red {
    color: #de0000;
  }
  .pointer {
    cursor: pointer;
  }
`
