import { useState } from 'react'
import axios from 'axios'
import { SimulationResponse } from '../types/API'

const useSimulateExecution = (executionId: string) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<SimulationResponse | null>(null)

  const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL
  const url = `${BASE_URL}/relayer-executor/task-executions`
  const token = localStorage.getItem('token')

  const header = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': `${token}`,
  }

  const simulateExecution = async (): Promise<void> => {
    setIsLoading(true)
    setData(null)
    try {
      const response = await axios.post<SimulationResponse>(`${url}/${executionId}/simulate`, {}, { headers: header })
      setData(response.data)
      console.log('Simulation:', response.data)
    } catch (error) {
      console.error('Error simulate:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, data, simulateExecution }
}

export default useSimulateExecution
