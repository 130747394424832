import { useQuery, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'
import { refresh } from '../utils/web3-utils'
import { EnvironmentResponse } from '../types/API'

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL

const useEnvironmentList = (id?: string): UseQueryResult<EnvironmentResponse | null> => {
  return useQuery<EnvironmentResponse | null>({
    queryKey: ['environmentList', id],
    queryFn: () => fetchEnvironment(id!),
    enabled: !!id,
  })
}

const fetchEnvironment = async (id: string): Promise<EnvironmentResponse | null> => {
  try {
    const url = `${BASE_URL}/relayer-executor/environments`
    const token = localStorage.getItem('token')

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
      'x-auth-token': `${token}`,
    }

    const { data } = await axios.get<EnvironmentResponse[]>(url, { headers })

    const found = data.find((e) => e.mimicId.toLowerCase() === id.toLowerCase())
    return found || null
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      try {
        await refresh()
        return await fetchEnvironment(id)
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
      }
    } else {
      console.error('Error fetching environment data:', error)
      throw error
    }
    return null
  }
}

export default useEnvironmentList
