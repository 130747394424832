import { useContext } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import SubgraphsContext from '../context/SubgraphsContext'
import { Implementation } from '../types/graphql'

const useRegistry = (): UseQueryResult<Record<string, Implementation[]>> => {
  const subgraphs = useContext(SubgraphsContext)

  return useQuery<Record<string, Implementation[]>>({
    queryKey: ['useRegistry'],
    queryFn: () => fetchAllTasks(subgraphs),
  })
}

const fetchImplementations = async (chainId: string, subgraphs: Record<string, string>): Promise<Implementation[] | null> => {
  try {
    if (!chainId) return null

    const data = await request<{ implementations: Implementation[] }>(
      subgraphs[chainId],
      gql`
        query Implementations {
          implementations {
            id
            name
            stateless
            deprecated
          }
        }
      `
    )

    return data.implementations
  } catch (error) {
    console.error(`Error fetching implementations for chain ${chainId}:`, error)
    return null
  }
}

const fetchAllTasks = async (subgraphs: Record<string, string>): Promise<Record<string, Implementation[]>> => {
  const allData: Record<string, Implementation[]> = {}

  const promises = Object.keys(subgraphs).map((chainId) =>
    fetchImplementations(chainId, subgraphs).then((response) => ({
      chainId,
      response,
    }))
  )

  const results = await Promise.allSettled(promises)

  results.forEach((result) => {
    if (result.status === 'fulfilled' && result.value.response) {
      result.value.response.forEach((item) => {
        if (allData[item.name]) {
          allData[item.name].push(item)
        } else {
          allData[item.name] = [item]
        }
      })
    } else if (result.status === 'rejected') {
      console.error(`Error fetching data for chain ${result.reason.chainId}:`, result.reason)
    }
  })

  return allData
}

export default useRegistry
