/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import TokenListForm from './TokenListForm'
import MiniSwitch from '../formUtils/MiniSwitch'
import CustomConfirmationModal from '../CustomConfirmationModal'
import FormModal from '../FormModal'
import deleteIcon from '../../assets/delete.png'
import edit from '../../assets/edit.png'
import save from '../../assets/save.png'
import cancel from '../../assets/cancel.png'
import { ContainerTable, Flexbox, Loader, Right, ButtonViolet, Section } from '../../utils/styles'
import { refresh } from '../../utils/web3-utils'
import toast, { Toaster } from 'react-hot-toast'

interface TokenList {
  isActive: boolean
  lastUpdate: string
  name: string
  updatedAt?: string
  url: string
  _id?: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const TokenLists: React.FC = () => {
  const [addNew, setAddNew] = useState(false)
  const [tokenRegistryData, setTokenLists] = useState<TokenList[] | null>(null)
  const [customModalOpen, setCustomModalOpen] = useState(false)
  const [deleteParams, setDeleteParams] = useState<string | any>('')
  const [editItem, setEditItem] = useState<TokenList | any>(null)
  const [editIndex, setEditIndex] = useState<number | any>(null)
  const [editedProps, setEditedProps] = useState<TokenList | any>(null)

  const fetchTokenList = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get<TokenList[]>(`${URL}/token-registry/lists`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })

      setTokenLists(response.data)
    } catch (error: any) {
      if (error.response?.status === 401) {
        try {
          await refresh()
          await fetchTokenList()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
      console.error('Token list error:', error)
    }
  }

  useEffect(() => {
    fetchTokenList()
  }, [])

  const handleDeleteClick = (id: any) => {
    setDeleteParams(id)
    setCustomModalOpen(true)
  }

  const handleConfirmDelete = async () => {
    const id = deleteParams
    const token = localStorage.getItem('token')
    const url = `${URL}/token-registry/lists/${id}`

    try {
      await axios.delete(url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      fetchTokenList()
      toast.success('Token list successfully deleted')
    } catch (error: any) {
      if (error.response?.status === 401) {
        try {
          await refresh()
          await handleConfirmDelete()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
      console.error('There was an error deleting the token list item:', error)
    }

    setCustomModalOpen(false)
  }

  const handleEdit = (item: TokenList, index: number) => {
    setEditIndex(index)
    setEditItem(item)
    setEditedProps({
      name: item.name,
      url: item.url,
      isActive: item.isActive,
      lastUpdate: item.lastUpdate,
    })
  }

  const handleCancelEdit = () => {
    setEditIndex(null)
  }

  const handleSaveEdit = async () => {
    const token = localStorage.getItem('token')
    const url = `${URL}/token-registry/lists/${editItem?._id}`

    try {
      await axios.put(
        url,
        {
          name: editedProps?.name,
          url: editedProps?.url,
          isActive: editedProps?.isActive,
          lastUpdate: editedProps?.lastUpdate,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-auth-token': `${token}`,
          },
        }
      )
      fetchTokenList()
      toast('✅ Your changes were successfully saved')
    } catch (error: any) {
      if (error.response?.status === 403) {
        toast('❗️You dont have permissions to edit')
      } else if (error.response?.status === 401) {
        try {
          await refresh()
          await handleSaveEdit()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      } else {
        toast('❗️We couldnt save the edited changes')
      }
      console.error('There was an error deleting the token list item:', error)
    }
    setEditIndex(null)
  }

  const handleCancelDelete = () => {
    setCustomModalOpen(false)
  }

  const handleAddNewConfirm = () => {
    fetchTokenList()
    setAddNew(false)
  }

  const handleAddNewCancel = () => {
    setAddNew(false)
  }

  return (
    <Section>
      <Toaster position="top-right" />
      <Right>
        <ButtonViolet onClick={() => setAddNew(!addNew)}>+ Add new</ButtonViolet>
      </Right>
      {addNew && (
        <FormModal onConfirm={handleAddNewConfirm} onCancel={handleAddNewCancel}>
          <TokenListForm onSuccess={handleAddNewConfirm} />
        </FormModal>
      )}
      {tokenRegistryData ? (
        <>
          <ContainerTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>URL</th>
                <th>Last Update</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tokenRegistryData.map((item, index) => (
                <tr key={index}>
                  <td className="accent">
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedProps?.name}
                        onChange={(e) =>
                          setEditedProps({
                            ...editedProps,
                            name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      item.name
                    )}
                  </td>
                  <td>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedProps?.url}
                        onChange={(e) =>
                          setEditedProps({
                            ...editedProps,
                            url: e.target.value,
                          })
                        }
                      />
                    ) : (
                      item.url
                    )}
                  </td>
                  <td>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedProps?.lastUpdate}
                        onChange={(e) =>
                          setEditedProps({
                            ...editedProps,
                            lastUpdate: e.target.value,
                          })
                        }
                      />
                    ) : (
                      moment(item.lastUpdate).format('MMMM DD, YYYY [at] HH:mm:ss')
                    )}
                  </td>
                  <td>
                    {editIndex === index ? (
                      <MiniSwitch
                        ison={editedProps.isActive}
                        onToggle={() =>
                          setEditedProps({
                            ...editedProps,
                            isActive: !editedProps.isActive,
                          })
                        }
                      />
                    ) : item.isActive ? (
                      '✅'
                    ) : (
                      '❌'
                    )}
                  </td>
                  <td>
                    {editIndex === index ? (
                      <Flexbox>
                        <img onClick={handleSaveEdit} src={save} alt="Save" />
                        <img onClick={handleCancelEdit} src={cancel} alt="Cancel" />
                      </Flexbox>
                    ) : (
                      <img onClick={() => handleEdit(item, index)} src={edit} alt="Edit" />
                    )}
                  </td>
                  <td>
                    <img onClick={() => handleDeleteClick(item._id)} src={deleteIcon} alt="Delete" />
                  </td>
                </tr>
              ))}
            </tbody>
          </ContainerTable>
          {customModalOpen && (
            <CustomConfirmationModal
              message="Are you sure you want to delete this token list item?"
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </Section>
  )
}

export default TokenLists
