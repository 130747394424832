import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { EnvironmentResponse } from '../types/API'

const fetchEnvironment = async (id: string): Promise<EnvironmentResponse> => {
  const token = localStorage.getItem('token')
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/relayer-executor/environments/${id}`

  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': token || '',
  }

  const response = await axios.get<EnvironmentResponse>(url, { headers })
  return response.data
}

const useEnvironment = (id: string | undefined) => {
  return useQuery<EnvironmentResponse>({
    queryKey: ['environment', id],
    queryFn: () => fetchEnvironment(id!),
    enabled: !!id,
  })
}

export default useEnvironment
