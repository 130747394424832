import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import { ScrollTable, ContainerTable } from '../../utils/styles'
import { refresh } from '../../utils/web3-utils'
import { ButtonViolet, Loader } from '../../utils/styles'

interface LogGroups {
  creationTime: number
  arn: string
  logGroupClass: string
  logGroupName: string
  metricFilterCount: number
}
interface Data {
  logGroups: LogGroups[]
  nextToken: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const LogsStreams: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [logsData, setLogsStreams] = useState<LogGroups[] | null>(null)
  const [next, setNext] = useState<string | null>(null)
  const [button, setButton] = useState({ disabled: false, message: 'See more' })

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get<Data>(`${URL}/logs`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })

      setLogsStreams(response.data?.logGroups)
      setNext(response.data?.nextToken || null)
    } catch (error: any) {
      if (error.response?.status === 401) {
        try {
          await refresh()
          await fetchData()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
      console.error('Token list error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleRowClick = (logGroupName: string) => {
    navigate(`${location.pathname}/groups?name=${logGroupName}`, {
      replace: true,
    })
  }

  const handleSeeMore = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get<Data>(`${URL}/logs`, {
        params: {
          nextToken: next,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      if (response.data?.logGroups && response.data?.logGroups.length > 0) {
        setLogsStreams(response.data?.logGroups)
      } else if (response?.data?.logGroups?.length === 0) {
        setButton({ disabled: true, message: 'No more logs' })
      }
    } catch (error) {
      console.error('There was an error with the web3 form:', error)
    }
  }

  return (
    <div>
      {logsData ? (
        <>
          <ScrollTable>
            <ContainerTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Arn</th>
                <th>Created at</th>
                <th>Class</th>
                <th>Metric filter Count</th>
              </tr>
            </thead>
            <tbody>
              {logsData.map((item, index) => (
                <tr key={index} onClick={() => handleRowClick(item.logGroupName)}>
                  <td className="accent">{item.logGroupName}</td>
                  <td>{item.arn}</td>
                  <td>{new Date(item.creationTime).toLocaleString()}</td>
                  <td>{item.logGroupClass}</td>
                  <td>{item.metricFilterCount}</td>
                </tr>
              ))}
            </tbody>
          </ContainerTable>
          </ScrollTable>
          {next && (
            <ButtonViolet onClick={handleSeeMore} disabled={button.disabled}>
              {button.message}
            </ButtonViolet>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default LogsStreams
