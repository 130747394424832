import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { GasUsedResponse } from '../types/API'

const fetchGas = async (id: string, startDate: Date | null, endDate: Date | null, tasks: string[], chainId: string): Promise<GasUsedResponse> => {
  const token = localStorage.getItem('token')
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/relayer-executor/environments/${id}/gas-used`

  const params = {
    chainIds: [chainId],
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    tasks,
  }

  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': token || '',
  }

  const response = await axios.get<GasUsedResponse>(url, { params, headers })
  return response.data
}

const useGas = (id: string | undefined, startDate: Date | null, endDate: Date | null, tasks: string[], chainId: string) => {
  return useQuery<GasUsedResponse>({
    queryKey: ['gas', id, startDate, endDate, tasks, chainId],
    queryFn: () => fetchGas(id!, startDate, endDate, tasks, chainId),
    enabled: !!id && !!startDate && !!endDate,
  })
}

export default useGas
