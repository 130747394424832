import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { refresh } from '../utils/web3-utils'
import { TokenInfo } from '../types/API'

const useTokenInfo = (address: string, chainId: number) => {
  return useQuery<TokenInfo, Error>({
    queryKey: ['token', chainId, address],
    queryFn: () => fetchTokenInfo(address, chainId),
  })
}

const fetchTokenInfo = async (address: string, chainId: number): Promise<TokenInfo> => {
  if (!address) return Promise.reject(new Error('No address provided'))
  const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL
  const url = `${BASE_URL}/public/tokens/${chainId}/${address}`

  try {
    const { data } = await axios.get<TokenInfo>(url)
    return data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response?.status === 401) {
      try {
        await refresh()
        return await fetchTokenInfo(address, chainId)
      } catch (refreshError) {
        console.error('Error: Unable to refresh token. Please log in again.')
        return Promise.reject(refreshError)
      }
    }
    throw error
  }
}

export default useTokenInfo
