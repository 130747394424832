import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { FeesResponse } from '../types/API'

const fetchFees = async (id: string, startDate: Date | null, endDate: Date | null, tasks: string[], chainId: string): Promise<FeesResponse> => {
  const token = localStorage.getItem('token')
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/relayer-executor/environments/${id}/fees`

  const params = {
    chainIds: [chainId],
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    tasks,
  }

  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': token || '',
  }

  const response = await axios.get<FeesResponse>(url, { params, headers })
  return response.data
}

const useFees = (id: string | undefined, startDate: Date | null, endDate: Date | null, tasks: string[], chainId: string) => {
  return useQuery<FeesResponse>({
    queryKey: ['fees', id, startDate, endDate, tasks, chainId],
    queryFn: () => fetchFees(id!, startDate, endDate, tasks, chainId),
    enabled: !!id && !!startDate && !!endDate,
  })
}

export default useFees
