import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ChainStatsResponse } from '../types/API'

const fetchChainInfo = async (chainId: number): Promise<ChainStatsResponse> => {
  const token = localStorage.getItem('token')
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/relayer-executor/chains/${chainId}/stats`

  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    'x-auth-token': token || '',
  }

  const response = await axios.get<ChainStatsResponse>(url, { headers })
  return response.data
}

const useChainStats = (chainId: number | undefined) => {
  return useQuery<ChainStatsResponse>({
    queryKey: ['chainInfo', chainId],
    queryFn: () => fetchChainInfo(chainId!),
    enabled: !!chainId,
  })
}

export default useChainStats
