import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import bg from '../../../assets/bg.png'
import { refresh } from '../../../utils/web3-utils'
import { parseName } from '../../../utils/math-utils'
import { ButtonViolet, ButtonWhite, Message } from '../../../utils/styles'
import CustomInput from '../../general/Form'
import { Form } from '../../../utils/typescript-styles'

interface ApiSetting {
  _id: string
  rateLimitRequests: number
  rateLimitWindowMs: number
  corsAllowedOrigins: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const ApiSettings: React.FC = () => {
  const [apiSettings, setApiSettings] = useState<ApiSetting | null>(null)
  const [message, setMessage] = useState('')
  const [editedSettings, setEditedSettings] = useState<Partial<ApiSetting> | null>(null)

  useEffect(() => {
    const fetchApiSettings = async () => {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.get(`${URL}/api/settings`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'application/json',
            'x-auth-token': `${token}`,
          },
        })
        setApiSettings(response.data)
        setEditedSettings(response.data)
      } catch (error: any) {
        if (error.response?.status === 401) {
          try {
            await refresh()
            await fetchApiSettings()
          } catch (refreshError) {
            console.error('Error: Unable to refresh token. Please log in again.')
          }
        }
        console.error('Error al obtener las configuraciones de la API:', error)
      }
    }

    fetchApiSettings()
  }, [])

  const handleCancelEditClick = () => {
    setEditedSettings(apiSettings)
  }

  const handleSaveClick = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const token = localStorage.getItem('token')
      const apiData = {
        ...editedSettings,
        corsAllowedOrigins: editedSettings?.corsAllowedOrigins?.split(','),
      }
      await axios
        .put(`${URL}/api/settings`, apiData, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'application/json',
            'x-auth-token': `${token}`,
          },
        })
        .then((response) => {
          setMessage('The API form was succesfully updated')
        })
        .catch((error) => {
          localStorage.setItem('put', error.toString())
        })
    } catch (error: any) {
      if (error.response?.status === 401) {
        try {
          await refresh()
          await handleSaveClick(e)
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
      console.error('Error al guardar las configuraciones de la API:', error)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setEditedSettings((prevSettings: any) => ({
      ...prevSettings,
      [name]: value,
    }))
  }

  return (
    <>
      {apiSettings ? (
        <>
          <Form onSubmit={handleSaveClick}>
            {message !== '' ? (
              <Message>
                <span>{message}</span>
                <span className="close" onClick={() => setMessage('')}>
                  X
                </span>
              </Message>
            ) : (
              <>
                <div className="container">
                  {Object.entries(editedSettings as ApiSetting).map(
                    ([key, value]) =>
                      key !== '__v' &&
                      key !== 'createdAt' &&
                      key !== 'updatedAt' &&
                      key !== 'lastUpdate' &&
                      key !== '_id' && (
                        <div key={key} className="input-group">
                          <CustomInput
                            type="text"
                            name={key}
                            label={parseName(key)}
                            value={String(value)}
                            onChange={handleInputChange}
                            disabled={key === '_id' || key === 'updatedAt'}
                          />
                        </div>
                      )
                  )}
                </div>
                <div className="buttons">
                  <ButtonViolet type="submit">Save</ButtonViolet>
                  <ButtonWhite onClick={handleCancelEditClick}>Cancel</ButtonWhite>
                </div>
              </>
            )}
          </Form>
        </>
      ) : (
        <p>Loading configurations...</p>
      )}
    </>
  )
}

const ApiSection = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 874px;
`

export default ApiSettings
