import React, { useRef, useEffect } from 'react'
import Chart, { ChartData } from 'chart.js/auto'
import styled from 'styled-components'
import { SlippageResponse } from '../../../types/API'

interface SlippageChartProps {
  data: SlippageResponse
}

const SlippageChart: React.FC<SlippageChartProps> = ({ data }) => {
  const lineData: Record<string, number> = {}
  let totalizer = 0

  for (const value of Object.values(data)) {
    for (const [date, { averageSlippage }] of Object.entries(value)) {
      if (lineData[date]) {
        lineData[date] += averageSlippage
      } else {
        lineData[date] = averageSlippage
      }
      totalizer += averageSlippage
    }
  }

  const processData = () => {
    let allDateLabels: string[] = []
    Object.values(data).forEach((dates) => {
      const currentDates = Object.keys(dates)
      allDateLabels = Array.from(new Set([...allDateLabels, ...currentDates]))
    })

    allDateLabels.sort()

    return { dateLabels: allDateLabels }
  }

  const { dateLabels } = processData()

  const chartData: ChartData<'line', number[], string> = {
    labels: dateLabels,
    datasets: [
      {
        type: 'line',
        fill: true,
        label: 'Slippage',
        yAxisID: 'tasks-y-axis',
        data: Object.values(lineData),
        borderColor: 'rgba(252, 206, 20, 1)',
        backgroundColor: 'rgba(252, 206, 20, 0.4)',
        pointBackgroundColor: 'rgba(252, 206, 20, 1)',
        tension: 0.8,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointHoverBackgroundColor: 'rgba(252, 206, 20, 1)',
        pointHoverBorderColor: 'rgba(252, 206, 20, 1)',
      },
    ],
  }

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const chartRef = useRef<Chart | null>(null)

  const createChart = () => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')
      if (ctx) {
        chartRef.current = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: {
            responsive: true,
            scales: {
              'tasks-y-axis': {
                beginAtZero: true,
              },
            },
          },
        })
      }
    }
  }

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy()
    }
  }

  useEffect(() => {
    createChart()

    return () => {
      destroyChart()
    }
  }, [data])

  return (
    <>
      <h1>
        Slippage {totalizer > 0 && <Tot>Total: {totalizer.toFixed(2)}</Tot>}
      </h1>
      <div>
        <canvas ref={canvasRef} />
      </div>
    </>
  )
}

const Tot = styled.p`
  font-size: 14px;
  display: inline-flex;
  background: rgba(252, 206, 20, 1);
  padding: 5px 10px;
  border-radius: 22px;
  margin-left: 18px;
`

export default SlippageChart
