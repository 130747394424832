import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import moment from 'moment'
import { ScrollTable, ContainerTable, Loader } from '../../utils/styles'
import { refresh } from '../../utils/web3-utils'
import { formatDuration } from '../../hooks/useCrons'

interface Status {
  suspend: boolean
  schedule: string
  name: string
  lastCompletionTime: any
  lastStartTime: any
  lastScheduleTime: any
  nextScheduleTime: any
  _id: string
  active: boolean
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const StatusRelayer: React.FC = () => {
  const [statusRelayerData, setData] = useState<Status[] | null>(null)
  const [customModalOpen, setCustomModalOpen] = useState(false)
  const [deleteParams, setDeleteParams] = useState<string>('')

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get<Status[]>(`${URL}/crons`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'x-auth-token': `${token}`,
        },
      })
      setData(response.data)
    } catch (error: any) {
      if (error.response?.status === 401) {
        try {
          await refresh()
          await fetchData()
        } catch (refreshError) {
          console.error('Error: Unable to refresh token. Please log in again.')
        }
      }
      console.error('There was an error loading the data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const crons = statusRelayerData
    ? statusRelayerData.filter((obj) => !obj.name.startsWith('relayer-executor-cron'))
    : null
  const relayers = statusRelayerData
    ? statusRelayerData.filter((obj) => obj.name.startsWith('relayer-executor-cron'))
    : null

  return (
    <div>
      {statusRelayerData ? (
        <>
          <br />
          <br />
          <h1>Relayer Executor</h1>
          <ScrollTable>
            <ContainerTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Duration</th>
                <th>Last Start Time</th>
                <th>Last Completion Time</th>
                <th>Last Schedule Time</th>
                <th>Next Schedule Time</th>
                <th>Schedule</th>
                <th>Suspended</th>
              </tr>
            </thead>
            <tbody>
              {relayers?.map((item, index) => (
                <tr key={index}>
                  <td className="accent">{item.name}</td>
                  <td>
                    {formatDuration(moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime))))}
                    {moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime))).asMinutes() <
                      2 && item.active !== true ? (
                      <> 🚨</>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{moment(item.lastStartTime).format('DD/MM/YY HH:mm[hs]')}</td>
                  <td>
                    {item.active === true ? 'Running 🏃‍♂️' : moment(item.lastCompletionTime).format('DD/MM/YY HH:mm[hs]')}
                  </td>
                  <td>{moment(item.lastScheduleTime).format('DD/MM/YY HH:mm[hs]')}</td>

                  <td>{moment(item.nextScheduleTime).format('DD/MM/YY HH:mm[hs]')}</td>
                  <td>{item.schedule}</td>
                  <td>{item.suspend ? '🔴' : '🟢'}</td>
                </tr>
              ))}
            </tbody>
          </ContainerTable>
          </ScrollTable>
          <br />
          <br />
          <br />
          <br />
          <h1>Others</h1>
          <ScrollTable>
            <ContainerTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Duration</th>
                <th>Last Start Time</th>
                <th>Last Completion Time</th>
                <th>Last Schedule Time</th>
                <th>Next Schedule Time</th>
                <th>Schedule</th>
                <th>Suspended</th>
              </tr>
            </thead>
            <tbody>
              {crons?.map((item, index) => (
                <tr key={index}>
                  <td className="accent">{item.name}</td>
                  <td>
                    {formatDuration(moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime))))}
                    {moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime))).asMinutes() <
                      2 && item.active !== true ? (
                      <> 🚨</>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{moment(item.lastStartTime).format('DD/MM/YY HH:mm[hs]')}</td>
                  <td>
                    {item.active === true ? 'Running 🏃‍♂️' : moment(item.lastCompletionTime).format('DD/MM/YY HH:mm[hs]')}
                  </td>
                  <td>{moment(item.lastScheduleTime).format('DD/MM/YY HH:mm[hs]')}</td>

                  <td>{moment(item.nextScheduleTime).format('DD/MM/YY HH:mm[hs]')}</td>
                  <td>{item.schedule}</td>
                  <td>{item.suspend ? '🔴' : '🟢'}</td>
                </tr>
              ))}
            </tbody>
          </ContainerTable>
          </ScrollTable>
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default StatusRelayer
